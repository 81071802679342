import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  ArrowForward,
  CancelRounded,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from '@mui/icons-material';
import {
  theme,
  CircleCancel,
  CircleNotApplicableSelected,
  CircleNotApplicableUnSelected,
  CircleNotApplicableWarningSelected,
  CircleNotApplicableWarningUnSelected,
} from '@konecorp/ui-library';

import {
  AnswerValue,
  Attachment,
  Deviation,
  DeviationStatus,
  DeviationVariation,
  PutAnswerPayload,
  Question,
  QuestionValueType,
  SupervisorInstallerCheckListQuestions,
} from '../../schemas';
import DeviationForm, {
  CreateDeviationPayload,
  DeviationFormPrefill,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import Context, { InstallationContext } from '../../context';
import IconButtonGroup, { IconButtonGroupType } from '../../components/IconButtonGroup';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';
import UploadWidget from '../../components/UploadWidget';
import { useTranslation } from 'react-i18next';
import { FileType } from '../../helpers/upload-download';
// import UploadWidget from '../../components/UploadWidget';
export interface AnswerValueWithDeviationProps {
  question: Question | SupervisorInstallerCheckListQuestions;
  questionSetId?: string;
  questionSequence: number;
  answer?: AnswerValue | null;
  onAnswer: (
    newAnswer: PutAnswerPayload,
    deviation?: CreateDeviationPayload | EditDeviationPayload
  ) => Promise<void>;
  isReadOnly?: boolean;
}

const AnswerValueWithDeviation = (props: AnswerValueWithDeviationProps): JSX.Element => {
  const { question, questionSetId, questionSequence, answer, onAnswer, isReadOnly } =
    props;
  const { t } = useTranslation();
  const { installationData } = useContext(Context);
  const { deviations } = useContext(InstallationContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deviationToEdit, setDeviationToEdit] = useState<Deviation>();
  const [prefill, setPrefill] = useState<Partial<DeviationFormPrefill>>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>((answer as File[]) || []);

  const answerPayload = {
    value: true,
    tag: 'tag' in question ? question.tag : '',
    position: 'position' in question ? question.position : '',
    timestamp: Date.now(),
  };
  const answerPayloadNot = {
    value: 'notApplicable',
    tag: 'tag' in question ? question.tag : '',
    position: 'position' in question ? question.position : '',
    timestamp: Date.now(),
  };

  const qualityReviewDeviations = deviations.filter(
    (deviation) =>
      deviation.questionSetId === questionSetId &&
      deviation.questionSequence === questionSequence &&
      deviation.variation === DeviationVariation.REJECT
  );

  const answerPayloadNotApplicableWarning = {
    value: 'notApplicableWarning',
    tag: 'tag' in question ? question.tag : '',
    position: 'position' in question ? question.position : '',
    timestamp: Date.now(),
  };

  const sortedDeviations = qualityReviewDeviations.sort(
    (first, second) =>
      new Date(second.createdAt).getTime() - new Date(first.createdAt).getTime()
  );

  const latestOpenDeviation = sortedDeviations.find(
    ({ status }) => status === DeviationStatus.OPEN
  );

  const latestClosedDeviation = sortedDeviations.find(
    ({ status }) => status === DeviationStatus.CLOSED
  );

  const isYes = answer === true && !latestOpenDeviation;

  const openCreateDeviationFormDialog = (prefill: DeviationFormPrefill) => {
    setPrefill(prefill);
    setDialogOpen(true);
  };

  const openEditDeviationFormDialog = (deviation?: Deviation) => {
    setDeviationToEdit(deviation);
    setDialogOpen(true);
  };

  const closeDeviationFormDialog = () => {
    setDeviationToEdit(undefined);
    setPrefill(undefined);
    setDialogOpen(false);
  };
  const handleUploadButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (selectedFiles.length < 5) {
        setSelectedFiles((selectedFiles) => {
          const updatedFiles = [...selectedFiles, file];

          const answerPayloadUpload = {
            value: updatedFiles,
            tag: 'tag' in question ? question.tag : '',
            position: 'position' in question ? question.position : '',
            timestamp: Date.now(),
          };
          onAnswer(answerPayloadUpload);
          return updatedFiles;
        });
      } else {
        alert('You can upload only 5 files');
      }
    }
  };
  const deleteAttachmentsLocally = (file: File | Attachment) => {
    setSelectedFiles(selectedFiles.filter((f) => f !== file));
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    const answerPayloadUpload = {
      value: updatedFiles,
      tag: 'tag' in question ? question.tag : '',
      position: 'position' in question ? question.position : '',
      timestamp: Date.now(),
    };
    onAnswer(answerPayloadUpload);
  };
  const onClickQualityReviewYesAnswer = async () => {
    const deviationToDisplay = latestOpenDeviation || latestClosedDeviation;

    if (deviationToDisplay) {
      openEditDeviationFormDialog(deviationToDisplay);
    } else {
      await onAnswer(answerPayload);
    }
  };
  const onClickQualityReviewYesChoiceAnswer = async () => {
    answerPayload.value = true;
    await onAnswer(answerPayload);
  };
  const onClickQualityReviewNoChoiceAnswer = async () => {
    answerPayload.value = false;
    await onAnswer(answerPayload);
  };
  const onClickQualityReviewNotApplicableYes = async () => {
    const deviationToDisplay = latestOpenDeviation;

    if (deviationToDisplay) {
      await onAnswer(answerPayload);
      openEditDeviationFormDialog(deviationToDisplay);
    } else {
      await onAnswer(answerPayload);
    }
  };
  const onClickQualityReviewNoAnswer = () => {
    if (latestOpenDeviation) {
      openEditDeviationFormDialog(latestOpenDeviation);
    } else {
      if (!isInstallationCompleted(installationData?.status))
        openCreateDeviationFormDialog({
          questionSetId,
          questionSequence,
          variation: DeviationVariation.REJECT,
          blocker: false,
        });
    }
  };
  const onClickQualityReviewNotApplicable = async () => {
    const deviationToDisplay = latestOpenDeviation;
    if (deviationToDisplay) {
      await onAnswer(answerPayloadNot);
      openEditDeviationFormDialog(deviationToDisplay);
    } else {
      await onAnswer(answerPayloadNot);
    }
  };

  const onClickQualityReviewNotApplicableWarning = async () => {
    const deviationToDisplay = latestOpenDeviation;
    if (deviationToDisplay) {
      await onAnswer(answerPayloadNotApplicableWarning);
      openEditDeviationFormDialog(deviationToDisplay);
    } else {
      await onAnswer(answerPayloadNotApplicableWarning);
    }
  };

  const getNoIcon = () => {
    if (latestOpenDeviation) {
      return (
        <CancelRounded
          data-testid="active-reject-icon"
          htmlColor={theme.palette.error.dark}
          sx={{
            width: 45,
            height: 45,
            margin: `0 ${theme.spacing(-0.3)}px`
          }}
        />
      );
    }
    return (
      <CircleCancel
        data-testid="inactive-reject-icon"
        htmlColor={isReadOnly ? undefined : theme.palette.secondary.main}
        sx={{
          width: 40,
          height: 40,
        }}
      />
    );
  };
  const getNoChoiceIcon = (answer: boolean) => {
    if (!answer) {
      return (
        <CancelRounded
          data-testid="active-reject-icon"
          htmlColor={theme.palette.error.dark}
          sx={{
            width: 45,
            height: 45,
            margin: `0 ${theme.spacing(-0.3)}px`
          }}
        />
      );
    }
    return (
      <CircleCancel
        data-testid="inactive-reject-icon"
        htmlColor={isReadOnly ? undefined : theme.palette.secondary.main}
        sx={{
          width: 40,
          height: 40,
        }}
      />
    );
  };
  const getNotApplicable = () => {
    if (answer === 'notApplicable' && !latestOpenDeviation) {
      return (
        <CircleNotApplicableSelected
          data-testid="active-accept-icon"
          style={{
            padding: 3,
            width: 45,
            height: 45
          }}
          htmlColor="#59ab46"
        />
      );
    }
    return (
      <CircleNotApplicableUnSelected
        data-testid="inactive-accept-icon"
        style={{
          padding: 3,
          width: 45,
          height: 45
        }}
      />
    );
  };

  const getNotApplicableWarning = () => {
    if (answer === 'notApplicableWarning' && !latestOpenDeviation) {
      return (
        <Tooltip title={t('tooltip.text')!}>
          <IconButton>
            <CircleNotApplicableWarningSelected
              data-testid="active-accept-icon"
              style={{
                padding: 3,
                width: 45,
                height: 45
              }}
              htmlColor="#59ab46"
            />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('tooltip.text')!}>
        <IconButton>
          <CircleNotApplicableWarningUnSelected
            data-testid="inactive-accept-icon"
            style={{
              padding: 3,
              width: 45,
              height: 45
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const getYesIcon = () => {
    if (isYes) {
      return (
        <CheckCircleRounded
          data-testid="active-accept-icon"
          htmlColor="#59ab46"
          sx={{
            width: 45,
            height: 45
          }}
        />
      );
    }
    return (
      <CheckCircleOutlineRounded
        data-testid="inactive-accept-icon"
        sx={{
          width: 45,
          height: 45
        }}
      />
    );
  };
  const getYesChoiceIcon = (answer: boolean) => {
    if (answer) {
      return (
        <CheckCircleRounded
          data-testid="active-accept-icon"
          htmlColor="#59ab46"
          sx={{
            width: 45,
            height: 45
          }}
        />
      );
    }
    return (
      <CheckCircleOutlineRounded
        data-testid="inactive-accept-icon"
        sx={{
          width: 45,
          height: 45
        }}
      />
    );
  };

  const getArrowIcon = () => (
    <ArrowForward
      data-testid="arrow-icon"
      style={{
        color: theme.palette.secondary.main,
        margin: theme.spacing(-0.5),
        visibility:
          !latestOpenDeviation && !!latestClosedDeviation ? 'visible' : 'hidden',
      }}
    />
  );

  const warningOkNotokNabuttons: IconButtonGroupType[] = [
    {
      icon: getNotApplicableWarning(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNotApplicableWarning,
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'warning-na-button-ok-notokna',
    },
    {
      icon: getNoIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNoAnswer,
      iconSx: {
        padding: 0
      },
      dataTestId: 'no-button-ok-notokna-warning',
    },
    {
      icon: getArrowIcon(),
      iconSx: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      dataTestId: 'arrow-button',
    },
    {
      icon: getYesIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNotApplicableYes,
      iconSx: {
        padding: 0
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button-ok-notokna-warning',
    },
  ];

  const okNotokNabuttons: IconButtonGroupType[] = [
    {
      icon: getNotApplicable(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNotApplicable,
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'na-button-ok-notokna',
    },
    {
      icon: null,
      iconSx: {
        flex: 1,
        margin: '0 -2.5px',
        padding: 3,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button-ok-notokna-space',
    },
    {
      icon: getNoIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNoAnswer,
      iconSx: {
        padding: 0
      },
      dataTestId: 'no-button-ok-notokna',
    },
    {
      icon: getArrowIcon(),
      iconSx: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      dataTestId: 'arrow-button',
    },
    {
      icon: getYesIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNotApplicableYes,
      iconSx: {
        padding: 0
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button-ok-notokna',
    },
  ];

  const okNotokbuttons: IconButtonGroupType[] = [
    {
      icon: getNoIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNoAnswer,
      iconSx: {
        padding: 0
      },
      dataTestId: 'no-button',
    },
    {
      icon: getArrowIcon(),
      iconSx: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      dataTestId: 'arrow-button-ok-notok',
    },
    {
      icon: getYesIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewYesAnswer,
      iconSx: {
        padding: 0
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button-ok-notok',
    },
  ];

  const yesNobuttons: IconButtonGroupType[] = [
    {
      icon: getNoIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewNoAnswer,
      iconSx: {
        padding: 0
      },
      dataTestId: 'no-button',
    },
    {
      icon: getArrowIcon(),
      iconSx: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      dataTestId: 'arrow-button',
    },
    {
      icon: getYesIcon(),
      onClick: isReadOnly ? undefined : onClickQualityReviewYesAnswer,
      iconSx: {
        padding: 0
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button',
    },
  ];
  const yesNoChoicebuttons: IconButtonGroupType[] = [
    {
      icon: getNoChoiceIcon(answer === undefined || answer === true ? true : false),
      onClick: isReadOnly ? undefined : onClickQualityReviewNoChoiceAnswer,
      iconSx: {
        padding: 0
      },
      dataTestId: 'no-button',
    },
    {
      icon: getArrowIcon(),
      iconSx: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(1),
        },
      },
      dataTestId: 'arrow-button',
    },
    {
      icon: getYesChoiceIcon(answer === true ? true : false),
      onClick: isReadOnly ? undefined : onClickQualityReviewYesChoiceAnswer,
      iconSx: {
        padding: 0
      },
      color: isYes ? 'primary' : 'secondary',
      dataTestId: 'yes-button',
    },
  ];
  const renderComponents = (questionValueType: QuestionValueType) => {
    switch (questionValueType) {
      case 'ok_notok':
        return (
          <>
            <IconButtonGroup buttons={okNotokbuttons} />{' '}
            <Dialog open={isDialogOpen} fullScreen>
              {isDialogOpen && (
                <DialogContent sx={{ padding: 0 }}>
                  <DeviationForm
                    initialDeviation={deviationToEdit}
                    prefill={prefill}
                    onCreate={(deviation) => onAnswer(answerPayload, deviation)}
                    onEdit={(deviation) => onAnswer(answerPayload, deviation)}
                    onClear={closeDeviationFormDialog}
                  />
                </DialogContent>
              )}
            </Dialog>
          </>
        );
      case 'ok_notok_na':
        return (
          <>
            <IconButtonGroup buttons={okNotokNabuttons} />
            <Dialog open={isDialogOpen} fullScreen>
              {isDialogOpen && (
                <DialogContent sx={{ padding: 0 }}>
                  <DeviationForm
                    initialDeviation={deviationToEdit}
                    prefill={prefill}
                    onCreate={(deviation) => onAnswer(answerPayload, deviation)}
                    onEdit={(deviation) => {
                      answer === 'notApplicable'
                        ? onAnswer(answerPayloadNot, deviation)
                        : onAnswer(answerPayload, deviation);
                    }}
                    onClear={closeDeviationFormDialog}
                  />
                </DialogContent>
              )}
            </Dialog>
          </>
        );
      case 'yes_no':
        return (
          <>
            <IconButtonGroup buttons={yesNobuttons} />
            <Dialog open={isDialogOpen} fullScreen>
              {isDialogOpen && (
                <DialogContent sx={{ padding: 0 }}>
                  <DeviationForm
                    initialDeviation={deviationToEdit}
                    prefill={prefill}
                    onCreate={(deviation) => onAnswer(answerPayload, deviation)}
                    onEdit={(deviation) => onAnswer(answerPayload, deviation)}
                    onClear={closeDeviationFormDialog}
                  />
                </DialogContent>
              )}
            </Dialog>
          </>
        );
      case 'yes_no_choice':
        return (
          <>
            <IconButtonGroup buttons={yesNoChoicebuttons} />
          </>
        );
      case QuestionValueType.WARNING_OK_NOTOK_NA:
        return (
          <>
            <IconButtonGroup buttons={warningOkNotokNabuttons} />
            <Dialog open={isDialogOpen} fullScreen>
              {isDialogOpen && (
                <DialogContent sx={{ padding: 0 }}>
                  <DeviationForm
                    initialDeviation={deviationToEdit}
                    prefill={prefill}
                    onCreate={(deviation) => onAnswer(answerPayload, deviation)}
                    onEdit={(deviation) => {
                      answer === 'notApplicableWarning'
                        ? onAnswer(answerPayloadNotApplicableWarning, deviation)
                        : onAnswer(answerPayload, deviation);
                    }}
                    onClear={closeDeviationFormDialog}
                  />
                </DialogContent>
              )}
            </Dialog>
          </>
        );
      case 'picture':
        return (
          <>
            <UploadWidget
              handleUploadButton={handleUploadButton}
              selectedFiles={selectedFiles}
              fileType={FileType.ATTACHMENT}
              deleteAttachmentsLocally={deleteAttachmentsLocally}
              label={t('')}
              questionValueType={questionValueType}
              questionSequence={questionSequence}
            />
          </>
        );
      default:
        return <></>;
    }
  };
  return <>{renderComponents(question.valueType || QuestionValueType.DEFAULT)}</>;
};

export default AnswerValueWithDeviation;