import React, {
  ChangeEvent,
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  JSX,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled
} from '@mui/material';

export interface CustomAutocompleteData {
  id: string;
  label: string;
  legalFirstName?: string;
  legalLastName?: string;
}

export interface CustomAutocompleteProps {
  data: CustomAutocompleteData[];
  disabled?: boolean;
  id: string;
  label: string;
  onChange?: (value: CustomAutocompleteData) => void;
  loading?: boolean;
  required?: boolean;
  selectedValue?: string;
  size?: 'medium' | 'small';
  width?: string | number;
}

const Container = styled('div')<{ width: string | number | undefined }>(({ width }) => ({
  position: 'relative',
  width: width || '100%',
  display: 'inline-block',
  '& .MuiTextField-root': {
    width: '100%',
  },
}));

const CustomAutocomplete = (
  props: PropsWithChildren<CustomAutocompleteProps>
): JSX.Element => {

  const { t } = useTranslation();

  const { onChange, id, loading, disabled, data, selectedValue, required, label } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [suggestions, setSuggestions] = useState<CustomAutocompleteData[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnClickListItem = (item?: CustomAutocompleteData) => {
    if (onChange && item) {
      onChange(item);
    }
  };

  const handleOnChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    focusInputField();
  };

  useEffect(() => {
    const lowerCasedSearchText = searchText.toLowerCase();
    const suggestions = lowerCasedSearchText
      ? data.filter((d) => {
          if (d.legalFirstName || d.legalLastName) {
            const checkPrefix = (name: string) => {
              return name
                .toLowerCase()
                .split(' ')
                .some(
                  (word) =>
                    word.startsWith(lowerCasedSearchText.slice(0, 3)) ||
                    word.startsWith(lowerCasedSearchText.slice(0, 4))
                );
            };
            return (
              (d.legalFirstName && checkPrefix(d.legalFirstName)) ||
              (d.legalLastName && checkPrefix(d.legalLastName)) ||
              d.id.toLowerCase().includes(lowerCasedSearchText)
            );
          } else {
            return (
              d.label.toLowerCase().includes(lowerCasedSearchText) ||
              d.id.toLowerCase().includes(lowerCasedSearchText)
            );
          }
        })
      : data;
    setSuggestions(suggestions);
  }, [searchText, data]);

  const focusInputField = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const findById = (id: CustomAutocompleteData['id'] | undefined) =>
    data.find((d) => d.id === id);

  return (
    <Container width={props.width}>
      <FormControl
        variant="outlined"
        required={required}
        fullWidth
        size={props.size || 'medium'}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedValue}
          renderValue={() => findById(selectedValue)?.label}
          data-testid={`${id}-custom-auto-complete-select`}
          disabled={disabled}
          inputProps={{
            'data-testid': `${id}-select-field`,
          }}
          /**
           * onKeyPress helps to keep focus on the inputfield
           * when user searches for options
           */
          onKeyPress={focusInputField}
          /**
           * Below timeout allows sometime for the inputfield
           * to mount and then ref can focus on it.
           */
          onOpen={() => setTimeout(focusInputField, 200)}
          onClose={() => {
            setSearchText('');
          }}
          onChange={(event) => {
            handleOnClickListItem(findById(event.target.value as string));
          }}
          label={label}
        >
          <TextField
            key="search-input-field"
            value={searchText}
            /**
             * This is required in order to prevent any change that gets
             * triggered to the Select because of clicking on the input field
             */
            onClickCapture={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={handleOnChangeSearchText}
            InputProps={{
              endAdornment: loading ? (
                <CircularProgress color="inherit" size={20} data-testid="loading-icon" />
              ) : null,
            }}
            inputProps={{
              'data-testid': `${id}-text-field`,
            }}
            inputRef={inputRef}
            fullWidth
            autoFocus
            variant="outlined"
            size={props.size || 'medium'}
          />
          {loading ? (
            <MenuItem disabled>{t('autoComplete.loading')}</MenuItem>
          ) : suggestions.length ? (
            suggestions.map((item) => (
              <MenuItem
                key={`select-option-${item.id}`}
                data-testid={`suggestions-${item.id}`}
                value={item.id}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{t('autoComplete.noOption')}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Container>
  );
};

export default CustomAutocomplete;