import React, { ChangeEvent, JSX } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  Card,
  styled,
} from '@mui/material';

export type RoutingQuestionProps = {
  questionText: string;
  answerOptions: string[];
  answer: string;
  onAnswerChange: (answer: string) => void;
  className?: string;
  readOnly?: boolean;
};

const StyledRadioGroup = styled(RadioGroup)(({theme}) => ({
  '& .MuiFormControlLabel-root': {
    height: theme.spacing(5),
  },
}));

const StyledQuestionCard = styled(Card)(({theme}) => ({
  padding: theme.spacing(2),
}));

const RoutingQuestion = (props: RoutingQuestionProps): JSX.Element => {
  const { answer, answerOptions, questionText, className, readOnly, onAnswerChange } =
    props;

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    onAnswerChange(event.target.value);
  };

  const arrayIndexToAlphabet = (index: number): string => {
    return String.fromCharCode(index + 1 + 64);
  };

  return (
    <StyledQuestionCard className={className} variant="outlined">
      <Typography>{questionText}</Typography>
      <FormControl>
        <StyledRadioGroup
          aria-label="routing-answer"
          name="routing-answer"
          value={answer}
          onChange={handleRadioButtonChange}
        >
          {answerOptions.map((option, index) => {
            return (
              <FormControlLabel
                disabled={readOnly}
                key={`routing-answer-${option}`}
                value={option}
                control={<Radio color="primary" data-testid={`radio-${option}`} />}
                label={`${arrayIndexToAlphabet(index)} - ${option}`}
              />
            );
          })}
        </StyledRadioGroup>
      </FormControl>
    </StyledQuestionCard>
  );
};

export default RoutingQuestion;
