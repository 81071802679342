import React, { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Grid2, IconButton, Link, styled, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material';
import { theme } from '@konecorp/ui-library';

const RowLevel1 = styled(Grid2)(() => ({
  backgroundColor: '#005A93',
  color: '#FFFFFF',
  '& p': {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  '& .MuiIconButton-root': {
    paddingTop: theme.spacing(1),
    color: '#FFFFFF',
  },
  height: theme.typography.fontSize * 3,
}));

const StyledGrid = styled(Grid2)(() => ({
  minHeight: theme.typography.fontSize * 3,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

type OtherToolsProps = {
  equipmentNumber: string;
};

type LinkData = { name: string; url: string };

const getLinks = (equipmentNumber: string): LinkData[] => [
  ...(process.env.REACT_APP_TRACE_PDM_URL
    ? [
      {
        name: 'TracePDM',
        url: `${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${equipmentNumber}`,
      },
    ]
    : []),
  ...(process.env.REACT_APP_LINK_TO_TIP
    ? [{ name: 'TIP', url: process.env.REACT_APP_LINK_TO_TIP }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_QFB
    ? [{ name: 'QFB', url: process.env.REACT_APP_LINK_TO_QFB }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_QIM
    ? [{ name: 'QIM', url: process.env.REACT_APP_LINK_TO_QIM }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_TACO
    ? [{ name: 'TACO', url: process.env.REACT_APP_LINK_TO_TACO }]
    : []),
  ...(process.env.REACT_APP_LINK_TO_WORKDAY
    ? [{ name: 'Workday', url: process.env.REACT_APP_LINK_TO_WORKDAY }]
    : []),
];

const OtherTools = (props: OtherToolsProps): JSX.Element => {
  const { t } = useTranslation();

  const { equipmentNumber } = props;

  const [open, setOpen] = useState(false);
  const [links, setLinks] = useState<LinkData[]>([]);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  useEffect(() => {
    setLinks(getLinks(equipmentNumber));
  }, [equipmentNumber]);

  return (
    <>
      <RowLevel1
        data-testid="other-tools-header"
        container
        onClick={() => setOpen(!open)}
      >
        <Grid2 size={{ xs: 11 }}>
          <Typography>{t('otherTools.header')}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 1 }}>
          <IconButton aria-label="expand row" size="small">
            {chevron}
          </IconButton>
        </Grid2>
      </RowLevel1>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {links.map((link, i) => (
          <StyledGrid
            container
            sx={{
              backgroundColor: i % 2 ? '#FFFFFF' : '#F5F6F7'
            }}
            key={`link-${i}`}
          >
            <Grid2 size={{ xs: 12 }}>
              <Link href={link.url} target={'_blank'}>
                <Box display={'flex'}>
                  <Typography>{link.name}</Typography>
                  <OpenInNew />
                </Box>
              </Link>
            </Grid2>
          </StyledGrid>
        ))}
      </Collapse>
    </>
  );
};

export default OtherTools;
