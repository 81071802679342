/* eslint-disable no-console,@typescript-eslint/no-unused-vars */
import React, { Fragment, JSX, useContext, useEffect, useState } from 'react';
import { green, grey, red, yellow } from '@mui/material/colors';
import StartIcon from '@mui/icons-material/AssignmentReturn';
import HandoverIcon from '@mui/icons-material/AssignmentReturn';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { fetchContacts, fetchEmployee, get } from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Contact,
  ContactRole,
  Deviation,
  Employee,
  Handover,
  Installation,
  InstallationStatus,
  isAssignment,
  isSubcontractor,
  Scenario,
  SpecialUserIds,
  Worker,
} from '../../schemas';
import { Box, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { formatDate } from '../../helpers/formating';
import { theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import { InstallationContext } from '../../context';
import { getQuestionSetSummary, QuestionSetSummary } from '../../helpers/question';
import {
  getInstallationStatusIndex,
  installationStatuses,
} from '../../helpers/getInstallationLists';
import { useGetToken } from '../../hooks/useGetToken';
import { uniqBy } from 'lodash';

const StatusListItemWrapper = styled(Box)(() => ({
  alignItems: 'baseline',
  display: 'flex',
  margin: '0 auto 0.6rem auto',
  maxWidth: '800px',
}));

const StyledBox = styled(Box)(() => ({
  width: '1.5rem'
}));

const StyledStartIcon = styled(StartIcon)(() => ({
  transform: 'scaleX(-1)',
}));

const ListStatus = styled(List)(() => ({
  padding: '0'
}));

const StatusListItem = styled(ListItem)(() => ({
  backgroundColor: '#eef0f1',
  minHeight: '2.4rem',
  padding: '0 0.8rem',
}));

const ProgressListItem = styled(ListItem)(() => ({
  alignItems: 'center',
  backgroundColor: '#e5eff8',
  display: 'flex',
  height: '2.5rem',
  justifyContent: 'space-between',
  margin: '0 auto 0.6rem auto',
  maxWidth: '800px',
  padding: '0 0.5rem',
  width: '100%',
  '& > *': {
    marginRight: '0.5rem',
    '&:last-child': {
      marginRight: '0',
      marginLeft: 'auto',
    },
  },
}));

const StyledCheckOutlinedIcon = styled(CheckOutlinedIcon)(() => ({
  marginRight: '1rem'
}));

export type InstallationProgressProps = {
  installation: Installation | null;
  networkNumber: string;
};

const forFinalInspectionStatusIndex = getInstallationStatusIndex(
  InstallationStatus.FOR_FINAL_INSPECTION
);

/** Return correct statuses to display given currentStatus */
export const getStatusListData = (
  currentStatus: InstallationStatus
): InstallationStatus[] | null => {
  const maxStatusIndex = getInstallationStatusIndex(
    InstallationStatus.INSTALLATION_ARCHIVED
  );
  const currentStatusIndex = Math.min(
    getInstallationStatusIndex(currentStatus),
    maxStatusIndex
  );
  if (currentStatusIndex < forFinalInspectionStatusIndex) return null;

  return installationStatuses
    .filter(
      // filter out out-of-range statuses
      (_, index) => index >= forFinalInspectionStatusIndex && index <= currentStatusIndex
    )
    .filter((status) =>
      // seb rejected is a special case. Show it only if current status is seb rejected,
      // otherwise exclude it from progress view
      status === InstallationStatus.SEB_REJECTED
        ? currentStatus === InstallationStatus.SEB_REJECTED
        : true
    );
};

const StatusList = (
  installationStatus: InstallationStatus,
  sebSupervisorEmail?: string
): JSX.Element | null => {
  const { t } = useTranslation();

  const listItems = getStatusListData(installationStatus)?.map((status) => (
    <StatusListItemWrapper>
      <StyledBox />
      <StatusListItem key={status}>
        <StyledCheckOutlinedIcon
          htmlColor={theme.palette.info.dark}
        />
        <Typography variant="body2" color="textSecondary">
          {status === InstallationStatus.FOR_SEB_ACCEPTANCE && sebSupervisorEmail !== ''
            ? t('progressView.handoverToSebAndHisEmail', { email: sebSupervisorEmail })
            : t(`progressView.status.${status}`)}
        </Typography>
      </StatusListItem>
    </StatusListItemWrapper>
  ));

  return <ListStatus>{listItems}</ListStatus>;
};

export const QuestionList = (props: {
  role: ActivityDifferentiator;
  installation: Installation | null;
  deviations: Deviation[];
}): JSX.Element | null => {
  const { i18n } = useTranslation();
  const { role, installation, deviations } = props;

  const questionSetSummary = getQuestionSetSummary(role, installation, deviations, i18n);

  const getIconData = (summary: QuestionSetSummary): { color: string; label: string } => {
    if (summary.isCompleted) return { color: green[600], label: 'phase complete icon' };
    if (summary.isStarted) return { color: yellow[600], label: 'phase started icon' };
    return { color: grey[300], label: 'phase incomplete icon' };
  };

  return (
    <ListStatus key={`phase-status-display-${role}`}>
      {questionSetSummary.map((summary) => (
        <StatusListItemWrapper>
          <StyledBox>
            {summary.hasDeviations && (
              <WarningIcon
                fontSize="small"
                htmlColor={red[600]}
                aria-label="phase deviations icon"
              />
            )}
          </StyledBox>
          <StatusListItem
            key={`phase-status-${summary.setId}`}
          >
            <StyledCheckOutlinedIcon
              htmlColor={getIconData(summary).color}
              aria-label={getIconData(summary).label}
            />
            <ListItemText secondary={summary.description} />
            <Typography variant="body2">{formatDate(summary.modifiedAt)}</Typography>
          </StatusListItem>
        </StatusListItemWrapper>
      ))}
    </ListStatus>
  );
};

const fetchHandovers = async (jwtToken: string, networkNumber: string) => {
  try {
    const handoversResponse: Handover[] = await get(
      `v1/installations/${networkNumber}/handovers`,
      jwtToken
    );
    return Array.isArray(handoversResponse) ? handoversResponse : [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const InstallationProgress = (props: InstallationProgressProps): JSX.Element => {
  const { networkNumber, installation } = props;
  const { deviations } = useContext(InstallationContext);
  const installationStatus = installation?.status ?? InstallationStatus.TO_BE_STARTED;
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();

  const [workers, setWorkers] = useState<Worker[]>([]);
  const [handovers, setHandovers] = useState<Handover[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const byRole = (role: ActivityDifferentiator) => (worker: Worker) =>
    worker.activityDifferentiator === role;

  const byStartDate = (a: Worker, b: Worker) => {
    const dateA = isAssignment(a) ? a.assignmentStartDate : a.plannedStartDate;
    const dateB = isAssignment(b) ? b.assignmentStartDate : b.plannedStartDate;
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  };

  useEffect(() => {
    const assignees = installation?.assignees || [];
    const subcontractors = uniqBy(
      installation?.subcontractors || [],
      'activityDifferentiator'
    );
    const workers = [...assignees, ...subcontractors].sort(byStartDate);

    const installers = workers.filter(byRole(ActivityDifferentiator.INST));
    const testers = workers.filter(byRole(ActivityDifferentiator.CMSN));
    const supervisors = workers.filter(byRole(ActivityDifferentiator.SPV));

    setWorkers([...installers, ...testers, ...supervisors]);

    return () => {
      setWorkers([]);
    };
  }, [installation]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getTokenFunction();
        const handovers = await fetchHandovers(accessToken, networkNumber);
        setHandovers(handovers);
        const contacts = await fetchContacts(networkNumber, accessToken);
        setContacts(contacts);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [installation]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getTokenFunction();

        const assignees = installation?.assignees || [];
        const supervisorNumber = installation?.supervisorNumber;

        const employeeIds = assignees.map(
          (assignee) => assignee.koneResourcePersonalNumber
        );

        if (supervisorNumber) {
          employeeIds.push(supervisorNumber);
        }

        let employees: Employee[] = [];

        for (const employeeId of employeeIds) {
          const employee = await fetchEmployee(employeeId, accessToken);
          employees = employee ? [...employees, employee] : employees;
        }

        setEmployees(employees);
      } catch (error) {
        console.error(error);
        setEmployees([]);
      }
    })();
  }, [installation]);

  const getWorkersByRole = (role: ActivityDifferentiator) => workers.filter(byRole(role));

  const getHandoverByRole = (role: ActivityDifferentiator) => {
    return handovers
      .sort((first, second) => first.modifiedAt.localeCompare(second.modifiedAt))
      .find((handover) => handover.role === role);
  };

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((employee) => employee.employeeId === employeeId);
    return employee ? `${employee.legalFirstName} ${employee.legalLastName}` : employeeId;
  };

  const getSubcontractorName = (role: ActivityDifferentiator) => {
    const subcontractor = workers.filter(isSubcontractor).find(byRole(role));
    return subcontractor?.subcontractor.name || 'Subcontractor';
  };

  const sebSupervisorEmails = contacts
    .filter((contact) => contact.role === ContactRole.SEB_SPV && contact.email)
    .map((contact) => contact.email)
    .join(', ');

  const WorkerItem = (props: { worker: Worker }): JSX.Element => {
    const { worker } = props;
    const name = isAssignment(worker)
      ? getEmployeeName(worker.koneResourcePersonalNumber)
      : worker.subcontractor.name;
    const role = worker.activityDifferentiator;
    const startDate = isAssignment(worker)
      ? worker.assignmentStartDate
      : worker.plannedStartDate;

    return (
      <ProgressListItem>
        <StyledStartIcon htmlColor={theme.palette.info.main} />
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body2">{role}</Typography>
        <Typography variant="body2">{formatDate(startDate)}</Typography>
      </ProgressListItem>
    );
  };

  const HandoverItem = (props: { role: ActivityDifferentiator }): JSX.Element => {
    const { role } = props;
    const handover = getHandoverByRole(role);

    if (!handover) return <></>;

    const name =
      handover.creator === SpecialUserIds.SUBCONTRACTOR_INSTALLER ||
        handover.creator === SpecialUserIds.SUBCONTRACTOR_TESTER
        ? getSubcontractorName(role)
        : getEmployeeName(handover.creator);

    return (
      <ProgressListItem>
        <HandoverIcon htmlColor={theme.palette.success.main} />
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body2">{handover.role}</Typography>
        <Typography variant="body2">{formatDate(handover.modifiedAt)}</Typography>
      </ProgressListItem>
    );
  };

  const isInstallerSupervisorScenario =
    installation?.scenario === Scenario.INSTALLER_SUPERVISOR;

  const rolesToDisplay = [
    ActivityDifferentiator.INST,
    ...(isInstallerSupervisorScenario ? [] : [ActivityDifferentiator.CMSN]),
    ActivityDifferentiator.SPV,
  ];

  return (
    <Box width='100%' role={'listbox'} data-testid="progress-view">
      {workers.length === 0 && (
        <List>
          <ProgressListItem>
            <Typography variant="body2">{t('progressView.noProgress')}</Typography>
          </ProgressListItem>
        </List>
      )}
      {workers.length > 0 && (
        <List>
          {rolesToDisplay.map((role) => {
            const workersByRole = getWorkersByRole(role);

            return (
              <Fragment key={role}>
                {workersByRole.map((worker, i) => (
                  <WorkerItem worker={worker} key={`worker-${role}-${i}`} />
                ))}

                {workersByRole.length > 0 && (
                  <QuestionList
                    role={role}
                    installation={installation}
                    deviations={deviations}
                    key={`questions-${role}`}
                  />
                )}

                <HandoverItem role={role} key={`handover-${role}`} />
              </Fragment>
            );
          })}
        </List>
      )}
      {getInstallationStatusIndex(installationStatus) > forFinalInspectionStatusIndex &&
        StatusList(installationStatus, sebSupervisorEmails)}
    </Box>
  );
};

export default InstallationProgress;
