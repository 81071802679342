import React, { useEffect, useContext, useState, JSX } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { InstallationCardData } from '../../schemas';
import SupervisorNewInstallation from '../../components/SupervisorNewInstallation';
import Context from '../../context';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetToken } from '../../hooks/useGetToken';
import { getInstallationCardData } from '../../helpers/getInstallationLists';

type QueryParams = {
  networkNumber: string;
}

// NOTE!!: This container looks odd and simple now due to the fact that
// starting new installations for installer/tester has been moved to
// InstallationStarting container (and display within the workflow)

const Newinstallation = (): JSX.Element => {
  const { networkNumber } = useParams() as QueryParams;

  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [isSubcontractor] = useIfSubcontractor();

  const { toBeStartedInstallations, updateIsLoading } = useContext(Context);
  const [cardData, setCardData] = useState<InstallationCardData | undefined>(undefined);

  useEffect(() => {
    (async () => {
      //If KONE employee we need to fetch te whole list of installations belong to the current user
      if (!toBeStartedInstallations && !isSubcontractor) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (toBeStartedInstallations?.includes(networkNumber)) {
        try {
          updateIsLoading(true);
          const token = await getTokenFunction();
          const cardData = await getInstallationCardData(networkNumber, token);
          setCardData(cardData);
        } catch (e) {
          console.error('error while fetching to be started installation data', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [toBeStartedInstallations]);

  if (!toBeStartedInstallations?.includes(networkNumber)) {
    return <Navigate to="/" replace />;
  }

  if (cardData) {
    return (
      <SupervisorNewInstallation
        installation={cardData.installation}
        networkNumber={cardData.network.networkNumber}
      />
    );
  }

  return <></>;
};

export default Newinstallation;
