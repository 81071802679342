import React, { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { kebabCase } from 'lodash';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';

import { WorkerData, Filters, GroupType } from '../DeviationsList';

export type DeviationsListFiltersProps = {
  workers: WorkerData[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
  onClose: (reset?: boolean) => void;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const DeviationsListFilters = (props: DeviationsListFiltersProps): JSX.Element => {
  const { t } = useTranslation();

  const { workers, filters, setFilters, onClose } = props;

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, [event.target.name]: event.target.value });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, [event.target.name]: event.target.checked });

  return (
    <>
      <FormGroup>
        <StyledTextField
          name="assignedTo"
          label={t('deviationsList.assignedTo')}
          variant="outlined"
          defaultValue=""
          value={filters.assignedTo}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'assignedTo-input' }}
          fullWidth
          select
        >
          <MenuItem value="">{t('deviationsList.assignee.anyone')}</MenuItem>
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          name="createdBy"
          label={t('deviationsList.createdBy')}
          variant="outlined"
          defaultValue=""
          value={filters.createdBy}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'createdBy-input' }}
          fullWidth
          select
        >
          <MenuItem value="">{t('deviationsList.assignee.anyone')}</MenuItem>
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          name="groupBy"
          label={t('deviationsList.groupBy.header')}
          variant="outlined"
          defaultValue={GroupType.PRIORITY}
          value={filters.groupBy}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'groupBy-input' }}
          fullWidth
          select
        >
          <MenuItem value={GroupType.PRIORITY}>
            {t('deviationsList.groupBy.priority')}
          </MenuItem>
          <MenuItem value={GroupType.LOCATION}>
            {t('deviationsList.groupBy.location')}
          </MenuItem>
          <MenuItem value={GroupType.ASSIGNEE}>
            {t('deviationsList.groupBy.assignee')}
          </MenuItem>
        </StyledTextField>
        <FormLabel>{t('deviationsList.including')}</FormLabel>
        <FormControl>
          <FormControlLabel
            name="showFixed"
            control={
              <Checkbox
                checked={filters.showFixed}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showFixed')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="showDeviations"
            control={
              <Checkbox
                checked={filters.showDeviations}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showDeviations')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="showQrIssues"
            control={
              <Checkbox
                checked={filters.showQrIssues}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('deviationsList.showQrIssues')}
          />
        </FormControl>
      </FormGroup>
      <StyledButton
        fullWidth
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose()}
      >
        {t('deviationsList.applyButton')}
      </StyledButton>
      <StyledButton
        fullWidth
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose(true)}
      >
        {t('deviationsList.resetButton')}
      </StyledButton>
    </>
  );
};

export default DeviationsListFilters;