import React, { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { kebabCase } from 'lodash';

import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import { theme } from '@konecorp/ui-library';

import { Deviation } from '../../schemas';
import { WorkerData } from '../DeviationsList';

export type DeviationsListUpdateProps = {
  selectedDeviations: Deviation[];
  workers: WorkerData[];
  onUpdate: (payload: UpdateDeviationPayload) => void;
  onCancel: () => void;
};

export type UpdateDeviationPayload = {
  assignee: string;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const DeviationsListUpdate = (props: DeviationsListUpdateProps): JSX.Element => {
  const { t } = useTranslation();

  const [state, setState] = useState<UpdateDeviationPayload>({
    assignee: '',
  });

  const { selectedDeviations, workers, onUpdate, onCancel } = props;

  const isUpdateDisabled = !state.assignee;

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, [event.target.name]: event.target.value });

  return (
    <>
      <Box mb={2}>
        <Typography align="center">{`${selectedDeviations.length} ${t(
          'deviationsList.deviationsSelected'
        )}`}</Typography>
      </Box>
      <Box mb={2}>
        <Typography align="center">{t('deviationsListUpdate.info')}</Typography>
      </Box>
      <FormGroup>
        <StyledTextField
          name="assignee"
          label={t('deviationsListUpdate.assignee')}
          variant="outlined"
          defaultValue=""
          value={state.assignee}
          onChange={handleSelectChange}
          inputProps={{ 'data-testid': 'assignee-input' }}
          fullWidth
          select
        >
          {workers.map((worker) => (
            <MenuItem value={worker.employeeId} key={kebabCase(worker.displayName)}>
              {worker.displayName}
            </MenuItem>
          ))}
        </StyledTextField>
      </FormGroup>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <StyledButton
          color="primary"
          variant="contained"
          size="large"
          sx={{ marginRight: theme.spacing(1) }}
          disabled={isUpdateDisabled}
          onClick={() => onUpdate(state)}
          fullWidth
        >
          {t('deviationsListUpdate.update')}
        </StyledButton>
        <StyledButton
          color="primary"
          variant="outlined"
          size="large"
          onClick={onCancel}
          fullWidth
        >
          {t('deviationsListUpdate.cancel')}
        </StyledButton>
      </Box>
    </>
  );
};

export default DeviationsListUpdate;