import React, { JSX } from 'react';
import {
  Grid2,
  IconButton,
  Link,
  Typography,
  styled
} from '@mui/material';
import { DialogType } from '../TeamList';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../schemas';
import { DeleteForever } from '@mui/icons-material';

const AssignmentInfo = styled(Grid2)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  textTransform: 'uppercase',
  '& p': {
    cursor: 'pointer',
  },
}));

export type AddButtonProps = {
  contact: Contact;
  setSelectedItem: (item: Contact) => void;
  setShowDialog: (dialog: DialogType) => void;
  setItemToRemove: (item: Contact) => void;
};

const ContactInfoDetails = (props: AddButtonProps): JSX.Element => {
  const { contact } = props;
  const { setSelectedItem, setShowDialog, setItemToRemove } = props;
  const { t } = useTranslation();
  return (
    <AssignmentInfo container>
      <Grid2 size={{ xs: 5}}>
        <Typography>{t(`contactRoles.${contact.role}`)}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <Typography>
          <Link
            onClick={() => {
              setSelectedItem(contact);
              setShowDialog(DialogType.CONTACT);
            }}
          >
            {contact.firstName && contact.lastName
              ? `${contact.firstName} ${contact.lastName}`
              : contact.firstName || contact.lastName || '-'}
          </Link>
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 1 }} container justifyContent="flex-end" alignItems="flex-end">
        <IconButton
          data-testid={`remove-icon-contact-${contact.guid}`}
          style={{ padding: 0, margin: 0 }}
          onClick={() => {
            setItemToRemove(contact);
            setShowDialog(DialogType.REMOVE);
          }}
        >
          <DeleteForever />
        </IconButton>
      </Grid2>
    </AssignmentInfo>
  );
};

export default ContactInfoDetails;