import React, { JSX, PropsWithChildren } from 'react';
import { Grid2, styled, Typography } from '@mui/material';
import { Inbox } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface EmptyProps {
  message?: string;
  displayIcon?: boolean;
  ariaLabel?: string;
}

const InboxIcon = styled(Inbox)(() => ({
  fontSize: 60
}));

const Empty = ({
  message,
  displayIcon = true,
  ariaLabel = 'empty-component',
}: PropsWithChildren<EmptyProps>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid2
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="40vh"
      aria-label={ariaLabel}
    >
      {displayIcon && (
        <Grid2>
          <InboxIcon color="secondary" />
        </Grid2>
      )}
      <Grid2>
        <Typography align="center" variant="h6" color="secondary">
          {message || t('notifications.noData')}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default Empty;
