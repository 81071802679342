import React, { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  styled,
} from '@mui/material';

import { Filters } from '../OngoingInstallationsList';

export type NetworkListFiltersProps = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  onClose: (reset?: boolean) => void;
};

const StyledButton = styled(Button)(({theme}) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const NetworkListFilters = (props: NetworkListFiltersProps): JSX.Element => {
  const { t } = useTranslation();

  const { filters, setFilters, onClose } = props;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <FormGroup>
        <FormLabel>{t('myInstallation.show')}</FormLabel>
        <FormControl>
          <FormControlLabel
            name="NBS"
            control={
              <Checkbox
                checked={filters.NBS}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('installationTypes.seKiqc')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="MOD"
            control={
              <Checkbox
                checked={filters.MOD}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('installationTypes.partialMod')}
          />
        </FormControl>
      </FormGroup>
      <StyledButton
        fullWidth
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose()}
      >
        {t('deviationsList.applyButton')}
      </StyledButton>
      <StyledButton
        fullWidth
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose(true)}
      >
        {t('deviationsList.resetButton')}
      </StyledButton>
    </>
  );
};

export default NetworkListFilters;
