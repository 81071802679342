import React, {
  ChangeEvent,
  JSX,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { uniqBy } from 'lodash';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  styled,
  TextField,
} from '@mui/material';
import { CircleCancel, DeviationBlocker, theme } from '@konecorp/ui-library';

import Context from '../../context';
import {
  ActivityDifferentiator,
  Attachment,
  DEVIATION_LOCATIONS,
  DEVIATION_PHASES,
  DEVIATION_STATUSES,
  DEVIATION_TYPES,
  DeviationPhase,
  DeviationStatus,
  DeviationType,
  Deviation,
  DeviationVariation,
  DeviationLocation,
  InstallationStatus,
  locationMapping,
  SpecialUserIds,
  NetworkTags,
  UserComment,
} from '../../schemas';
import UploadWidget from '../UploadWidget';
import { fetchEmployeeFullName } from '../../helpers/fetch';
import {
  compareStatus,
  CompareStatusResult,
  isInstallationCompleted,
} from '../../helpers/getInstallationLists';
import { getSubcontractorId, useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useGetToken } from '../../hooks/useGetToken';

import CustomAutocomplete, { CustomAutocompleteData } from '../CustomAutocomplete';
import DeviationChangeHistory from '../DeviationChangeHistory';
import { FileType } from '../../helpers/upload-download';
import { WorkerData } from '../DeviationsList';

export type DeviationFormPrefill = Partial<
  Pick<
    Deviation,
    'blocker' | 'description' | 'variation' | 'questionSetId' | 'questionSequence'
  >
>;

export type DeviationFormProps = {
  initialDeviation?: Partial<Deviation>;
  prefill?: Partial<DeviationFormPrefill>;
  onCreate: (deviation: CreateDeviationPayload) => void;
  onEdit?: (deviation: EditDeviationPayload) => void;
  onClear: () => void;
};

const CircleCancelIcon = styled(CircleCancel)(() => ({
  width: 40,
  height: 40,
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: theme.spacing(0.4),
}));

export type CreateDeviationPayload = Pick<
  Deviation,
  | 'assignee'
  | 'blocker'
  | 'compliance'
  | 'delay'
  | 'userComment'
  | 'description'
  | 'ken'
  | 'phase'
  | 'location'
  | 'installationWorkflowStatus'
  | 'variation'
  | 'status'
  | 'type'
  | 'history'
  | 'questionSetId'
  | 'questionSequence'
  | 'closedBy'
  | 'createdBy'
> & {
  source: 'IES';
  files: (File | Attachment)[];
};

//TODO: we can only edit certain fields not everything straight from CreateDeviationPayload
export type EditDeviationPayload = CreateDeviationPayload &
  Pick<Deviation, 'guid' | 'history' | 'closedAt' | 'closedBy'>;

export type DeviationFormData = CreateDeviationPayload | EditDeviationPayload;

const isCreateMode = (
  deviation: DeviationFormData
): deviation is CreateDeviationPayload => !('guid' in deviation);

const isEditMode = (deviation: DeviationFormData): deviation is EditDeviationPayload =>
  'guid' in deviation;

const DeviationForm = (props: PropsWithChildren<DeviationFormProps>): JSX.Element => {
  const { initialDeviation, prefill, onCreate, onEdit, onClear } = props;
  const { isLoading, networkNumber, installationData, updateIsLoading } =
    useContext(Context);
  const [employeeId] = useGetUserData();
  const [role] = useGetCurrentUserRole();

  const isCreator = // when created by is undefined it means that deviation was just created by user
    !initialDeviation?.createdBy || initialDeviation.createdBy === employeeId;
  const workflowStatus = installationData?.status || InstallationStatus.TO_BE_STARTED;

  const preChecklistPhase =
    compareStatus(workflowStatus, InstallationStatus.TO_BE_STARTED) ===
    CompareStatusResult.SAME;

  const installationPlanPhase =
    compareStatus(workflowStatus, InstallationStatus.INSTALLER_ACCEPTED) ===
    CompareStatusResult.SAME;

  const isInstallerStartingPhase =
    compareStatus(workflowStatus, InstallationStatus.FOR_INSTALLER_ACCEPTANCE) ===
    CompareStatusResult.SAME;

  const isTesterStartingPhase =
    compareStatus(workflowStatus, InstallationStatus.FOR_TESTER_ACCEPTANCE) ===
    CompareStatusResult.SAME;

  const isTestingPhase =
    compareStatus(workflowStatus, InstallationStatus.FOR_TESTER_ACCEPTANCE) !==
    CompareStatusResult.BEFORE;

  const isNebSebHandoverPhase =
    compareStatus(workflowStatus, InstallationStatus.FOR_SEB_ACCEPTANCE) !==
    CompareStatusResult.BEFORE;

  const queryParams = new URLSearchParams(useLocation().search);
  const questionSetId = queryParams.get('questionSetId') || undefined;
  const questionSequence = Number(queryParams.get('questionNumber'));

  // changes to add subcontractor company name while closing deviations
  const deviationSubcontractors = uniqBy(
    installationData?.subcontractors || [],
    'activityDifferentiator'
  ).map<WorkerData>((subcontractor) => ({
    employeeId: getSubcontractorId(subcontractor.activityDifferentiator),
    activityDifferentiator: subcontractor.activityDifferentiator,
    displayName: subcontractor.subcontractor.name,
  }));

  const getUserName = (employeeId: string) => {
    let closedByName = employeeId;
    if (deviationSubcontractors.length > 0) {
      const subcontractor = deviationSubcontractors.find(
        (subcontractor) => subcontractor.activityDifferentiator === role
      );
      if (subcontractor) {
        closedByName = subcontractor.displayName;
      }
    }
    return closedByName;
  };

  const generateNewDeviation = (): CreateDeviationPayload => {
    const phaseEnumKey = Object.keys(DeviationPhase).find((key) => {
      return DeviationPhase[key as keyof typeof DeviationPhase] === questionSetId;
    }) as keyof typeof DeviationPhase;

    const phaseBasedOnQuestionSet = DeviationPhase[phaseEnumKey];

    const phase = (() => {
      if (preChecklistPhase) return DeviationPhase.TO_BE_STARTED;
      if (installationPlanPhase && installationData?.networkTag === NetworkTags.MOD)
        return DeviationPhase.INSTALLATION_PLAN;
      if (isInstallerStartingPhase) return DeviationPhase.START_OF_INSTALLATION;
      if (isNebSebHandoverPhase) return DeviationPhase.NEB_SEB_HANDOVER;
      if (isTestingPhase) return DeviationPhase.TESTING;
      if (questionSetId) return phaseBasedOnQuestionSet;
    })();

    const location = (() => {
      if (isInstallerStartingPhase || isTesterStartingPhase)
        return DeviationLocation.NOT_SPECIFIED;
      else return locationMapping.get(phaseBasedOnQuestionSet);
    })();
    return {
      assignee: installationData?.supervisorNumber || '',
      blocker: false,
      compliance: false,
      delay: 0,
      ken: installationData?.equipmentNumber || '',
      installationWorkflowStatus:
        installationData?.status || InstallationStatus.TO_BE_STARTED,
      source: 'IES',
      variation: DeviationVariation.DEVIATION,
      status: DeviationStatus.OPEN,
      description: '',
      type: isTestingPhase ? DeviationType.KONE : undefined,
      files: [],
      history: [],
      questionSetId,
      questionSequence,
      phase,
      location,
      createdBy: getUserName(employeeId),
      ...prefill,
    };
  };

  const storedDeviation = initialDeviation?.guid
    ? ({
        ...initialDeviation,
        /*
       when the deviation hasn't been sync, it will has the files property, we need it for when user open the form
       of the unsync deviation again, they will see it in the list
       In editDeviation (action) we call upload helper function, which will filter out the uploaded file (Attachment type), so this is safe
     */
        files: initialDeviation.files || [],
        userComment: initialDeviation.userComment || [{ comment: '', createdAt: '' }],
        location:
          initialDeviation.location || locationMapping.get(initialDeviation.phase),
      } as EditDeviationPayload)
    : generateNewDeviation();

  const [deviation, setDeviation] = useState<DeviationFormData>(storedDeviation);
  const [deviationFiles, setDeviationFiles] = useState<string[]>();
  const [comment, setComment] = useState<string>();

  const isOpenDeviation =
    isCreateMode(deviation) || storedDeviation?.status === DeviationStatus.OPEN;
  const canDeviationBeUpdated =
    !isInstallationCompleted(workflowStatus) && isOpenDeviation;

  const getDeviationTypes = () => {
    let filteredDeviationTypes;

    if (!isEditMode(deviation)) {
      filteredDeviationTypes = isTestingPhase
        ? DEVIATION_TYPES.filter(({ id }) =>
            [
              ...(isCreateMode(deviation) ? [] : [DeviationType.DEFAULT]),
              DeviationType.KONE,
              DeviationType.BUILDER,
            ].includes(id)
          )
        : DEVIATION_TYPES.filter(({ id }) => id !== DeviationType.KONE);
    } else {
      if (deviation.phase === DeviationPhase.TESTING) {
        filteredDeviationTypes = DEVIATION_TYPES.filter(({ id }) =>
          [
            ...(isCreateMode(deviation) ? [] : [DeviationType.DEFAULT]),
            DeviationType.KONE,
            DeviationType.BUILDER,
          ].includes(id)
        );
      } else {
        filteredDeviationTypes = DEVIATION_TYPES.filter(
          ({ id }) => id !== DeviationType.KONE
        );
      }
    }
    return filteredDeviationTypes;
  };

  const phasesOnlyForTesting = [DeviationPhase.TESTING, DeviationPhase.NEB_SEB_HANDOVER];
  const deviationPhases = isTestingPhase
    ? DEVIATION_PHASES
    : DEVIATION_PHASES.filter(({ id }) => !phasesOnlyForTesting.includes(id));

  const [getTokenFunction] = useGetToken();
  const [assignees, setAssignees] = useState<CustomAutocompleteData[]>([]);

  const { t } = useTranslation();

  const subcontractors = uniqBy(
    installationData?.subcontractors || [],
    'activityDifferentiator'
  );

  useEffect(() => {
    if (networkNumber && installationData) {
      const fetchAndSetSupervisorName = async () => {
        try {
          updateIsLoading(true);
          const accessToken = await getTokenFunction();

          const isSupervisorAlreadyAssigned = installationData.assignees.some(
            ({ koneResourcePersonalNumber }) =>
              koneResourcePersonalNumber === installationData.supervisorNumber
          );

          const assigneeIds = installationData.assignees
            .map(({ koneResourcePersonalNumber }) => koneResourcePersonalNumber)
            .concat(
              installationData.supervisorNumber && !isSupervisorAlreadyAssigned
                ? [installationData.supervisorNumber]
                : []
            );

          const assignees = await Promise.all(
            assigneeIds.map(async (id) => ({
              id,
              label: await fetchEmployeeFullName(id, accessToken),
            }))
          );
          const subcontractor = subcontractors.map((subcontractor) => ({
            id: getSubcontractorId(subcontractor.activityDifferentiator),
            label: subcontractor.subcontractor.name,
          }));

          const builder = {
            id: SpecialUserIds.BUILDER,
            label: t('deviationsList.assignee.builder'),
          };

          setAssignees([...assignees, ...subcontractor, builder]);
        } catch (e) {
          console.error('Error while fetching supervisor name.', e);
        } finally {
          updateIsLoading(false);
        }
      };
      fetchAndSetSupervisorName();
    }
  }, [networkNumber]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    let updatedDeviation = { ...deviation };

    if (deviation?.userComment?.length === 1) {
      const updatedComment: UserComment = {
        ...deviation.userComment[0],
        fileNames: deviationFiles,
      };

      updatedDeviation = { ...deviation, userComment: [updatedComment] };
      setDeviation(updatedDeviation);
    }
    if (isEditMode(updatedDeviation)) {
      onEdit?.({ ...updatedDeviation });
    } else if (isCreateMode(updatedDeviation)) {
      onCreate({ ...updatedDeviation });
    }
    onClear();
  };

  const handleSelectValueChange = (id: string, value: CustomAutocompleteData) => {
    if (value.id === DeviationStatus.CLOSED) {
      const closedByName = getUserName(employeeId);
      return setDeviation({
        ...deviation,
        [id]: value.id,
        closedAt: new Date().toISOString(),
        closedBy: closedByName,
      });
    }
    if (id === 'type') {
      const assigneeId =
        assignees.find((assignee) => assignee.label === value.label)?.id ||
        installationData?.supervisorNumber;

      return setDeviation({
        ...deviation,
        assignee: assigneeId?.toString() || '',
        [id]: value.id as DeviationType,
      });
    }
    return setDeviation({ ...deviation, [id]: value.id });
  };

  const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'userComment') {
      const latestComment = {
        comment: value.trimStart(),
        createdAt: new Date().toISOString(),
      };
      setComment(value.trimStart());
      setDeviation({ ...deviation, userComment: [latestComment] });
    } else {
      setDeviation({ ...deviation, [name]: value.trimStart() });
    }
  };

  const handlePhaseValueChange = (value: CustomAutocompleteData) => {
    const phase = value.id as DeviationPhase;
    const location = locationMapping.get(value.id as DeviationPhase);
    setDeviation((deviation) => ({ ...deviation, phase, location }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { name } = event.target;
    return setDeviation({ ...deviation, [name]: checked });
  };

  const handleUploadButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setDeviation({ ...deviation, files: [...deviation.files, file] });
      setDeviationFiles((prevDeviationFiles) => [
        ...(prevDeviationFiles || []),
        file.name,
      ]);
    }
  };

  const getSelectedFiles = () => {
    const attachmentList =
      isEditMode(deviation) && deviation.history
        ? deviation.history.reduce(
            (acc, entry) => (entry.files ? [...acc, ...entry.files] : acc),
            [] as (File | Attachment)[]
          )
        : [];

    return attachmentList.concat(deviation.files);
  };

  // Only tester and NEB supervisor (before Service Engineer starts working) are allowed
  const isComplianceCheckboxEnabled =
    isCreator &&
    canDeviationBeUpdated &&
    (role === ActivityDifferentiator.CMSN ||
      (role === ActivityDifferentiator.SPV && !isNebSebHandoverPhase));

  const canBeUpdatedByCreator = isCreator && canDeviationBeUpdated;

  return (
    <Box
      padding={theme.spacing(1)}
      component="form"
      onSubmit={handleSubmit}
      data-testid={
        isEditMode(deviation) ? 'deviation-edit-form' : 'deviation-create-form'
      }
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 10 }}>
          <TextField
            inputProps={{
              'data-testid': 'description-field',
            }}
            id="description-field"
            label={
              isEditMode(deviation)
                ? t('deviation.description')
                : t('deviationForm.newReport')
            }
            placeholder={
              isEditMode(deviation)
                ? t('deviation.description')
                : t('deviationForm.newReport')
            }
            name="description"
            disabled={!canBeUpdatedByCreator}
            onChange={handleInputValueChange}
            value={deviation.description}
            variant="outlined"
            required
            fullWidth
            multiline
            rows={5}
            maxRows={5}
          />
        </Grid2>
        <Grid2
          container
          size={{ xs: 2 }}
          direction="row"
          alignContent="center"
          justifyContent="flex-end"
        >
          <CircleCancelIcon onClick={onClear} />
        </Grid2>
        <Grid2 size={{ xs: 7 }}>
          <CustomAutocomplete
            data={deviationPhases}
            id="process-phase"
            label={t('deviation.phase')}
            disabled={
              isEditMode(deviation) ||
              isTestingPhase ||
              isInstallerStartingPhase ||
              preChecklistPhase ||
              (installationPlanPhase &&
                installationData?.networkTag === NetworkTags.MOD) ||
              !canDeviationBeUpdated
            }
            required
            selectedValue={deviation.phase}
            onChange={handlePhaseValueChange}
          />
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <CustomAutocomplete
            data={[{ id: networkNumber, label: networkNumber }]}
            disabled
            id="network"
            label={t('deviation.network')}
            loading={isLoading || !canDeviationBeUpdated}
            required
            selectedValue={networkNumber}
          />
        </Grid2>
        <Grid2 size={{ xs: 7 }}>
          <CustomAutocomplete
            data={getDeviationTypes()}
            id="type"
            label={t('deviation.type')}
            selectedValue={deviation.type}
            onChange={(value) => handleSelectValueChange('type', value)}
            disabled={!canDeviationBeUpdated || isEditMode(deviation)}
            required={true}
          />
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <FormControl>
            <FormControlLabel
              value="compliance"
              control={
                <StyledCheckbox
                  data-testid="compliance-checkbox"
                  checked={deviation.compliance}
                  color="primary"
                  name="compliance"
                  onChange={handleCheckboxChange}
                  disabled={!isComplianceCheckboxEnabled || !canDeviationBeUpdated}
                />
              }
              label={t('deviation.compliance')}
              labelPlacement="start"
            />

            <FormControlLabel
              value="blocker"
              control={
                <StyledCheckbox
                  data-testid="blocker-checkbox"
                  checked={deviation.blocker}
                  color="primary"
                  name="blocker"
                  onChange={handleCheckboxChange}
                  disabled={!canBeUpdatedByCreator}
                />
              }
              label={
                <Box display="flex">
                  {t('deviation.blocker')}
                  &nbsp;
                  <DeviationBlocker />
                </Box>
              }
              labelPlacement="start"
            />
          </FormControl>
        </Grid2>
        <Grid2 size={{ xs: 7 }}>
          <CustomAutocomplete
            data={assignees}
            id="assignee"
            label={t('deviation.assignee')}
            disabled={isCreateMode(deviation) || !canDeviationBeUpdated}
            required
            selectedValue={deviation.assignee}
            onChange={(value) => handleSelectValueChange('assignee', value)}
          />
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <TextField
            inputProps={{
              'data-testid': 'delay-field',
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            id="delay-estimate"
            label={t('deviation.delayEstimate')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setDeviation({
                ...deviation,
                delay: Number(event.target.value),
              });
            }}
            type="number"
            value={deviation?.delay?.toString() || '0'}
            variant="outlined"
            fullWidth
            disabled={!canDeviationBeUpdated}
          />
        </Grid2>
        <Grid2 size={{ xs: 7 }}>
          <CustomAutocomplete
            data={DEVIATION_LOCATIONS}
            id="location"
            label={t('deviation.location')}
            selectedValue={deviation.location || ''}
            onChange={(value) => handleSelectValueChange('location', value)}
            disabled={isEditMode(deviation) || !isTestingPhase || !canDeviationBeUpdated}
          />
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <CustomAutocomplete
            data={DEVIATION_STATUSES}
            id="status"
            label={t('deviation.status')}
            selectedValue={deviation.status}
            onChange={(value) => handleSelectValueChange('status', value)}
            disabled={!canDeviationBeUpdated}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          {isEditMode(deviation) && (
            <TextField
              inputProps={{
                'data-testid': 'userComment-field',
              }}
              id="userComment-multiline-flexible"
              label={t('deviation.userComment')}
              multiline
              name="userComment"
              onChange={handleInputValueChange}
              rows={5}
              maxRows={5}
              value={comment}
              variant="outlined"
              fullWidth
              required={isEditMode(deviation)}
              disabled={!canDeviationBeUpdated}
            />
          )}
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <UploadWidget
            handleUploadButton={handleUploadButton}
            selectedFiles={getSelectedFiles()}
            fileType={FileType.DEVIATION}
            deleteAttachmentsLocally={(fileToRemove) => {
              setDeviation((deviation) => {
                const files = deviation.files.filter((file) => file !== fileToRemove);
                return { ...deviation, files };
              });
            }}
            label={t('deviationForm.addFile')}
            disabled={!canDeviationBeUpdated}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          {isEditMode(deviation) && deviation.history && (
            <DeviationChangeHistory
              history={deviation.history}
              subcontractors={subcontractors}
            />
          )}
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading || !canDeviationBeUpdated}
            data-testid="send-button"
            type="submit"
            disableElevation
            fullWidth
          >
            {t('deviationForm.save')}
          </Button>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={isLoading}
            data-testid="clear-button"
            onClick={onClear}
            fullWidth
          >
            {t('deviationForm.cancel')}
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DeviationForm;
