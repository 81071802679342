import React, { useEffect, useContext, useRef, JSX } from 'react';
import Context, { InstallationContext } from '../../context';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableBody, styled } from '@mui/material';

import Empty from '../../components/Empty';
import {
  QuestionSet,
  QuestionType,
  AnswerSet,
  PutAnswerPayload,
  Deviation,
  Attachment,
} from '../../schemas';
import ReviewQuestion from '../ReviewQuestion';
import {
  closeDeviation,
  createDeviation,
  editDeviation,
} from '../../helpers/deviationActions';
import { API_TYPE, post } from '../../helpers/fetch';
import NavigationButtons from '../../components/NavigationButtons';
import { CONTAINER_HEIGHT } from '../NetworkInstallation';
import { useUpdateAnswerInContext } from '../../hooks/useUpdateAnswerInContext';
import { useGetToken } from '../../hooks/useGetToken';
import SnagList from '../../components/SnagList';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import { InstallationActionName } from '../../reducers/installation';
import { uploadAttachments } from '../../helpers/upload-download';

type QuestionListProps = {
  questionSets?: QuestionSet[];
  answers?: AnswerSet[];
};

const QuestionListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(11),
  paddingTop: theme.spacing(1),
  overflowY: 'auto',
  maxHeight: CONTAINER_HEIGHT, // Replace with the actual value of CONTAINER_HEIGHT
  paddingBottom: theme.spacing(7),
}));

const QuestionsList = (props: QuestionListProps): JSX.Element => {
  const { updateIsLoading, networkNumber, updateErrorMessage } = useContext(Context);
  const { dispatch } = useContext(InstallationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [getTokenFunction] = useGetToken();
  const { t } = useTranslation();
  const inspectionMainContentRef = useRef<HTMLDivElement>(null);
  const [updateAnswerInContext] = useUpdateAnswerInContext();
  const scrollPositionRef = useRef<number>(0); // To store the scroll position
  const previousQuestionSetIdRef = useRef<string | null>(null); // To track the previous questionSetId

  const searchQuery = new URLSearchParams(location.search);
  const questionSetIdParam = searchQuery.get('questionSetId');

  const { questionSets, answers } = props;

  useEffect(() => {
    if (inspectionMainContentRef?.current) {
      // Reset scroll position to top if the questionSetId has changed
      if (previousQuestionSetIdRef.current !== questionSetIdParam) {
        inspectionMainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        scrollPositionRef.current = 0; // Reset saved position
      } else {
        // Restore the saved scroll position if the questionSetId is the same
        inspectionMainContentRef.current.scrollTo({
          top: scrollPositionRef.current,
          behavior: 'smooth',
        });
      }
      previousQuestionSetIdRef.current = questionSetIdParam; // Update the previous questionSetId
    }
  }, [questionSets, questionSetIdParam]);

  const questionsetIndex = questionSets?.findIndex(
    (qSet) => qSet.questionSetId === questionSetIdParam
  );
  const currentQuestionsetIndex =
    questionsetIndex && questionsetIndex !== -1 ? questionsetIndex : 0;

  const onChangeNavigation = (
    questionSetIdToNavigateTo: string,
    isGoingBackward: boolean
  ) => {
    // Reset the scroll position to the top
    scrollPositionRef.current = 0;
    if (inspectionMainContentRef.current) {
      inspectionMainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const foundQuestionSet = questionSets?.find(
      (x) => x.questionSetId === questionSetIdToNavigateTo
    );
    const prevQuestionsetLastQuestionNumber = foundQuestionSet?.questions
      ? foundQuestionSet.questions.length - 1
      : 0;
    if (isGoingBackward) {
      navigate(
        `?questionSetId=${questionSetIdToNavigateTo}&questionNumber=${prevQuestionsetLastQuestionNumber}`
      );
    } else {
      navigate(`?questionSetId=${questionSetIdToNavigateTo}&questionNumber=0`);
    }
  };

  const questionSetToShow = questionSets?.find(
    (question) => question.questionSetId === questionSetIdParam
  );

  const onSaveAnswer = async (
    newAnswer: PutAnswerPayload,
    questionSequenceNumber: number
  ) => {
    // Save the current scroll position
    if (inspectionMainContentRef.current) {
      scrollPositionRef.current = inspectionMainContentRef.current.scrollTop;
    }

    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    const files = newAnswer.value || [];
    const uploadFiles: File[] = [];
    const dbFiles: Attachment[] = [];
    if (Array.isArray(files)) {
      files.forEach((file) => {
        if (file instanceof File) {
          uploadFiles.push(file);
        } else {
          dbFiles.push(file);
        }
      });
    }
    try {
      if (Array.isArray(uploadFiles) && uploadFiles.length) {
        const attachments = await uploadAttachments({
          files: uploadFiles,
          networkNumber,
          questionSequenceNumber,
          questionSetIdParam: questionSetIdParam,
          jwtToken: accessToken,
        });
        newAnswer.value = [...dbFiles, ...(attachments || [])];
      }
      const updatedAnswer = await post(
        `v1/installations/${networkNumber}/answers/${questionSetIdParam}/${questionSequenceNumber}`,
        accessToken,
        API_TYPE.APPLICATION,
        newAnswer
      );
      updateAnswerInContext(updatedAnswer, questionSetIdParam, questionSequenceNumber);
    } catch (e) {
      console.error(e);
    }
    updateIsLoading(false);

    // Restore the scroll position
    if (inspectionMainContentRef.current) {
      inspectionMainContentRef.current.scrollTo({
        top: scrollPositionRef.current,
        behavior: 'smooth',
      });
    }
  };

  const closeSnag = async (snag: Deviation) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    try {
      const responseSnag = await closeDeviation(accessToken, networkNumber, snag);
      dispatch({ type: InstallationActionName.EDIT_DEVIATION, deviation: responseSnag });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotCloseSnag'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const renderQuestionList = (questionSet: QuestionSet) => {
    const { questions } = questionSet;
    const answerSets = answers?.find(
      (answerSet) => answerSet.questionSetId === questionSet.questionSetId
    );
    const globalArr: string[] = [];
    const answerValues = answerSets?.answers || [];
    // To validate duplicate values in landing door Location/identification question
    for (let index = 0; index < answerValues.length; index++) {
      if (answerValues[index]?.questionSetId === 'SE000107') {
        const ans = String(answerValues[index]?.value).toUpperCase();
        if (ans && !globalArr.includes(ans)) globalArr.push(ans);
      }
    }
    //for frontend display purpose, pick the correct background color
    let tableRowIndex = 0;
    const questionListRow: JSX.Element[] = [];
    let lastDummyQuestionIndex = -1;

    const isDummyQuestion = (index: number) =>
      questions[index]?.questionType?.toLowerCase() === QuestionType.DUMMY;
    for (let index = 0; index < questions.length; index++) {
      if (isDummyQuestion(index)) {
        tableRowIndex = 0;
        lastDummyQuestionIndex = index;
      } else {
        tableRowIndex++;
      }

      questionListRow.push(
        <ReviewQuestion
          key={`${questionSet.questionSetId}-${index}`}
          answer={answerValues[index]}
          question={questions[index]}
          questionSetId={questionSet.questionSetId}
          questionSequence={index}
          tableRowIndex={tableRowIndex}
          saveAnswer={(newAnswer) => onSaveAnswer(newAnswer, index)}
          onCreateDeviation={handleCreateDeviation}
          onEditDeviation={handleEditDeviation}
          globalArr={globalArr}
        />
      );

      if (index === questions.length - 1 || isDummyQuestion(index + 1)) {
        questionListRow.push(
          <SnagList
            id={`${lastDummyQuestionIndex}`}
            key={`${questionSet.questionSetId}-${lastDummyQuestionIndex}-snag`}
            questionSetId={questionSet.questionSetId}
            questionSequence={lastDummyQuestionIndex}
            onCreateSnag={handleCreateDeviation}
            onEditSnag={handleEditDeviation}
            onCloseSnag={closeSnag}
          />
        );
      }
    }
    return questionListRow;
  };

  const handleCreateDeviation = async (deviation: CreateDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);

    try {
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );
      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotCreateDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const handleEditDeviation = async (deviation: EditDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);
    try {
      const updatedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: updatedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotEditDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  return (
    <>
      <QuestionListContainer data-testid="questions-list" ref={inspectionMainContentRef}>
        {questionSets && questionSets.length > 0 && questionSetToShow !== undefined ? (
          <>
            <TableContainer>
              <Table>
                <TableBody>{renderQuestionList(questionSetToShow)}</TableBody>
              </Table>
            </TableContainer>
            <Box p={4}>
              <NavigationButtons
                onClickBackward={
                  currentQuestionsetIndex > 0 && questionSets
                    ? () =>
                        onChangeNavigation(
                          questionSets[currentQuestionsetIndex - 1]?.questionSetId,
                          true
                        )
                    : undefined
                }
                onClickForward={
                  questionSets && currentQuestionsetIndex < questionSets?.length - 1
                    ? () =>
                        onChangeNavigation(
                          questionSets[currentQuestionsetIndex + 1]?.questionSetId,
                          false
                        )
                    : undefined
                }
                backwardButtonText={t('inspectionPage.gobackButton')}
                forwardButtonText={t('inspectionPage.continueButton')}
              />
            </Box>
          </>
        ) : (
          <Empty displayIcon={false} message={t('inspectionPage.noQuestionSet')}></Empty>
        )}
      </QuestionListContainer>
    </>
  );
};

export default QuestionsList;
