import React, { JSX, useState } from 'react';
import { styled, TextField } from '@mui/material';
import { AnswerValue, PutAnswerPayload, Question } from '../../schemas';
import { InfoModal } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

const StyledTextField= styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  marginRight: theme.spacing(0.5),
  width: '97px',
  [theme.breakpoints.up('sm')]: {
    width: '113px',
  },
}));

export interface QuestionStringValueProps {
  question: Question;
  questionSetId: string;
  answer?: AnswerValue;
  onAnswer: (newAnswer: PutAnswerPayload) => Promise<void>;
  globalArr: string[];
  isReadOnly?: boolean;
}

const QuestionStringValue = (props: QuestionStringValueProps): JSX.Element => {
  const { question, answer = '', onAnswer, isReadOnly, globalArr, questionSetId } = props;
  const [value, setValue] = useState<string>(answer as string);
  const [isValid, setValid] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const regexPattern = /^[0-9]{1,2}[A,C]?$/;
  const { t } = useTranslation();
  let isLDOQuestion = false;
  if (questionSetId === 'SE000107') isLDOQuestion = true;
  const isValidString = (value: string): boolean =>
    !!value && new RegExp(regexPattern).test(value);

  if (isLDOQuestion && answer && !globalArr.includes(String(answer)))
    globalArr.push(String(answer));
  const saveAnswer = async (): Promise<void> => {
    if (!value || !isValid) {
      return setShowModal(true);
    }
    if (String(value) === answer) return;
    if (isLDOQuestion && globalArr.includes(value.toUpperCase())) {
      setValue('');
      return setShowDuplicateModal(true);
    } else {
      globalArr.push(String(value));
      const newAnswer: PutAnswerPayload = {
        value: String(value),
        tag: question?.tag || '',
        position: question?.position || '',
        timestamp: Date.now(),
      };
      await onAnswer(newAnswer);
    }
  };

  return (
    <>
      <StyledTextField
        variant="outlined"
        size="small"
        inputProps={
          isLDOQuestion
            ? { maxLength: 3, 'data-testid': 'question-string-input' }
            : { 'data-testid': 'question-string-input' }
        }
        value={value}
        error={!isValid}
        onChange={(event) => {
          if (isLDOQuestion) setValid(isValidString(event.target.value));
          setValue(event.target.value);
        }}
        onBlur={saveAnswer}
        disabled={isReadOnly}
      />
      {showModal && (
        <InfoModal
          open={true}
          message={t('reviewForm.landingDoorErrorMessage')}
          closeButtonText={t('connection.ok')}
          onClose={(): void => setShowModal(false)}
          isCenteredMessage
        />
      )}
      {showDuplicateModal && (
        <InfoModal
          open={true}
          message={t('reviewForm.landingDoorDuplicateErrorMessage')}
          closeButtonText={t('connection.ok')}
          onClose={(): void => setShowDuplicateModal(false)}
          isCenteredMessage
        />
      )}
    </>
  );
};

export default QuestionStringValue;
