import React, { JSX, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  IconButton,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import { DeleteForever } from '@mui/icons-material';

import { CircleCancel, theme } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../helpers/formating';
import { useGetToken } from '../../hooks/useGetToken';
import Context from '../../context';
import {
  API_TYPE,
  fetchContacts,
  fetchSubcontractors,
  fetchVendors,
  getEmployeesDataFromInstallation,
  post,
  put,
  remove,
  get,
  fetchContracts,
  fetchSiteInfos,
} from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Attachment,
  Contact,
  ContactRole,
  Employee,
  ExtendedInstallation,
  Installation,
  isAssignment,
  RoleSource,
  SiteInfo,
  SiteInfoType,
  Subcontractor,
  SubcontractorRecord,
  Vendor,
  Contract,
  ContractType,
} from '../../schemas';

import ContactForm from '../ContactForm';
import { SubcontractorForm } from '../SubcontractorForm';
import AddTechnicianForm from '../../containers/AddTechnicianForm';
import ErrorMessageDialog from '../ErrorMessageDialog';
import AddButton from '../AddButton';
import ContractForm from '../ContractForm';
import SiteInfoForm from '../SiteInfoForm';
import { uploadAttachments } from '../../helpers/upload-download';
import { useLocation } from 'react-router-dom';

type Worker = Assignment | SubcontractorRecord;

export type TeamListProps = {
  installation: Installation | ExtendedInstallation;
  networkNumber: string;
};

type FilterWorkersReturnType = {
  installers: Worker[];
  testers: Worker[];
  serviceEngineers: Worker[];
};

export enum DialogType {
  NONE,
  SUBCONTRACTOR,
  CONTACT,
  ADD_TECHNICIAN,
  REMOVE,
  LINKS,
  SITEINFO,
}

const AssigmentInfoContainer = styled(Grid2)(() => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  '& p': {
    cursor: 'pointer',
  },
  textTransform: 'uppercase',
}));

const AssigmentInfoName = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
}));

const StyledTypography = styled(Typography)(() => ({
  padding: theme.spacing(1),
  textTransform: 'uppercase',
}));

const DeleteButton = styled(IconButton)(() => ({
  padding: 0,
}));

const TeamListContainer = styled('div')(() => ({
  width: '100%',
}));

const CloseDialogIcon = styled(CircleCancel)(() => ({
  width: 30,
  height: 30,
  position: 'absolute',
  zIndex: 1,
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
}));

const SourceChipCommon = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  fontSize: '0.8em',
  fontWeight: 'bold',
  height: 'min-content',
  paddingLeft: theme.spacing(0.4),
  paddingRight: theme.spacing(0.4),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  marginRight: theme.spacing(0.5),
}));

const SourceChipLight = styled(SourceChipCommon)(({ theme }) => ({
  borderColor: theme.palette.grey[400],
}));

const SourceChipDark = styled(SourceChipCommon)(({ theme }) => ({
  borderColor: theme.palette.grey[700],
  backgroundColor: theme.palette.grey[700],
  color: theme.palette.grey[200],
}));

const TeamList = ({ installation, networkNumber }: TeamListProps): JSX.Element => {
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const { t } = useTranslation();
  const [getTokenFunction] = useGetToken();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    Worker | Contact | null | Contract | SiteInfo
  >(null);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NONE);
  const [technicianRole, setTechnicianRole] = useState<
    ActivityDifferentiator | undefined
  >(undefined);
  const [removeSubcontractorPopup, setRemoveSubcontractorPopup] =
    useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [siteInfo, setSiteInfo] = useState<SiteInfo[]>([]);
  const [potentialKoneAssignees, setPotentialKoneAssignees] = useState<
    Employee[] | undefined
  >(undefined);
  const [itemToRemove, setItemToRemove] = useState<
    Worker | Contact | Contract | SiteInfo | undefined
  >();
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorType, setErrorType] = useState<string>('');

  const fetchVendorsForInstallation = async (
    installation: Installation | ExtendedInstallation,
    accessToken: string
  ) => {
    const vendorsCountryKey = installation.customer?.countryKey;
    return vendorsCountryKey ? await fetchVendors(vendorsCountryKey, accessToken) : [];
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      updateIsLoading(true);
      try {
        const accessToken = await getTokenFunction();

        const [employees, subcontractors, vendors, contacts, contracts, siteInfo] =
          await Promise.all([
            getEmployeesDataFromInstallation(accessToken, installation as Installation),
            fetchSubcontractors(networkNumber, accessToken),
            fetchVendorsForInstallation(installation, accessToken),
            fetchContacts(networkNumber, accessToken),
            fetchContracts(networkNumber, accessToken),
            fetchSiteInfos(networkNumber, accessToken),
          ]);

        const workers = [...(installation.assignees ?? []), ...subcontractors];

        if (isMounted) {
          setEmployees(
            employees.filter((employee): employee is Employee => employee !== null)
          );
          setWorkers(workers);
          setVendors(vendors);
          setContacts(contacts);
          setContracts(contracts);
          setSiteInfo(siteInfo);
        }
      } catch (error) {
        if (isMounted) {
          updateErrorMessage({ message: t('teamList.cannotGetRequiredData'), error });
        }
      } finally {
        if (isMounted) {
          updateIsLoading(false);
        }
      }
    })();

    // Cleanup function to mark component as unmounted
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (technicianRole) {
        try {
          updateIsLoading(true);
          const companyCode = installation.companyCode || '';

          const token = await getTokenFunction();
          const employeesData: Employee[] = await get(
            `v1/employees?role=${ActivityDifferentiator.INST},${ActivityDifferentiator.CMSN}&companyCode=${companyCode}`,
            token
          );
          setPotentialKoneAssignees(employeesData);
        } catch (error) {
          updateErrorMessage({
            message: t('teamList.cannotGetKoneEmployeeData'),
            error,
          });
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [technicianRole]);

  const filterWorkersByRole = (workers: Worker[]): FilterWorkersReturnType => {
    const byRole = (role: ActivityDifferentiator) => (worker: Worker) =>
      worker.activityDifferentiator === role;
    return {
      installers: workers.filter(byRole(ActivityDifferentiator.INST)),
      testers: workers.filter(byRole(ActivityDifferentiator.CMSN)),
      serviceEngineers: workers.filter(byRole(ActivityDifferentiator.SEEN)),
    };
  };

  const handleAddTechnician = (activityDifferentiator: ActivityDifferentiator) => {
    setTechnicianRole(activityDifferentiator);
    setShowDialog(DialogType.ADD_TECHNICIAN);
    setSelectedItem(null);
  };

  const closeTechnicanForm = () => {
    setTechnicianRole(undefined);
    setPotentialKoneAssignees(undefined);
    setShowDialog(DialogType.NONE);
  };

  const createSubcontractor = async (
    subcontractor: Subcontractor,
    pincode: string,
    plannedStartDate: string,
    plannedEndDate: string
  ) => {
    const accessToken = await getTokenFunction();

    try {
      updateIsLoading(true);
      await post(
        `v1/subcontractor/${networkNumber}/access`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          ...subcontractor,
          pincode,
          plannedStartDate,
          plannedEndDate,
          activityDifferentiator: technicianRole,
        }
      );
      //TODO: maybe the POST request above should return the new subcontractor object instead of fetching everything again
      const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
      const currentWorkerWithoutSubcontractor = workers.filter(isAssignment);
      setWorkers([...currentWorkerWithoutSubcontractor, ...subcontractors]);
      closeTechnicanForm();
      updateIsLoading(false);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveSubcontractor'), error });
      updateIsLoading(false);
    }
  };

  const createAssignee = async (
    employeeId: string,
    assignmentStartDate: string,
    assignmentEndDate: string
  ) => {
    const accessToken = await getTokenFunction();

    try {
      updateIsLoading(true);
      const newAssignee: Assignment = await put(
        `v1/installations/${networkNumber}/assignee`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          assigneeId: employeeId,
          userRole: technicianRole,
          assignmentStartDate,
          assignmentEndDate,
        }
      );

      const newEmployeeInfo = potentialKoneAssignees?.find(
        (potentialAssignee) =>
          potentialAssignee.employeeId === newAssignee.koneResourcePersonalNumber
      );
      if (newEmployeeInfo) {
        setEmployees([...employees, newEmployeeInfo]);
      }
      setWorkers([...workers, newAssignee]);
      closeTechnicanForm();
      setShowDialog(DialogType.NONE);

      updateIsLoading(false);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveAssignee'), error });
      updateIsLoading(false);
    }
  };

  const removeSubcontractor = async (activityDifferentiator: string) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await remove(
        `v1/subcontractor/${networkNumber}/access/${activityDifferentiator}`,
        accessToken,
        API_TYPE.APPLICATION
      );

      const updatedWorkers = workers.filter(
        (worker) =>
          isAssignment(worker) || worker.activityDifferentiator !== activityDifferentiator
      );

      setRemoveSubcontractorPopup(false);
      setShowDialog(DialogType.NONE);
      setWorkers(updatedWorkers);
      updateIsLoading(false);
    } catch (error) {
      updateIsLoading(false);
      updateErrorMessage({ message: t('teamList.cannotRemoveSubcontractor'), error });
      setRemoveSubcontractorPopup(false);
    }
  };

  const removeItem = async (item: Worker) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      const url = isAssignment(item)
        ? `v1/installations/${networkNumber}/assignees/${item.koneResourcePersonalNumber}`
        : `v1/subcontractor/${networkNumber}/access/${item.activityDifferentiator}`;

      await remove(url, accessToken, API_TYPE.APPLICATION);

      const updatedWorkers = workers.filter((worker) =>
        isAssignment(item)
          ? !isAssignment(worker) ||
            worker.koneResourcePersonalNumber !== item.koneResourcePersonalNumber
          : isAssignment(worker) ||
            worker.activityDifferentiator !== item.activityDifferentiator
      );

      setShowDialog(DialogType.NONE);
      setWorkers(updatedWorkers);
      updateIsLoading(false);
    } catch (error) {
      updateIsLoading(false);
      updateErrorMessage({ message: t('teamList.cannotRemoveSubcontractor'), error });
    }
  };

  const sendContact = async (contactToSend: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      const returnedContact: Contact = await put(
        `v1/installations/${networkNumber}/contacts`,
        token,
        API_TYPE.APPLICATION,
        contactToSend
      );

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);

      const updatedContacts =
        contactToSend.guid !== returnedContact.guid
          ? [...contacts, returnedContact]
          : contacts.map((contact) =>
              contact.guid === returnedContact.guid ? returnedContact : contact
            );
      setContacts(updatedContacts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const sendContract = async (contractToSend: Contract) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      if (contractToSend.value) {
        delete contractToSend.value;
        contractToSend = { ...contractToSend, type: 'link' };
      }
      const returnedContract: Contract = await put(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        contractToSend
      );
      const updatedContract = {
        ...contractToSend,
        sk: returnedContract.sk,
      };

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);
      const updatedContracts =
        contractToSend.sk !== updatedContract.sk
          ? [...contracts, updatedContract]
          : contracts.map((contract) =>
              contract.sk === updatedContract.sk ? updatedContract : contract
            );
      setContracts(updatedContracts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const questionSequenceNumberParam = searchQuery.get('questionNumber');
  const sendSiteInfo = async (siteInfoToSend: SiteInfo) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      const uploadFiles: File[] = [];
      const dbFiles: Attachment[] = [];
      if (Array.isArray(siteInfoToSend.attachments)) {
        siteInfoToSend.attachments.forEach((selectedFile: any) => {
          if (selectedFile instanceof File) {
            uploadFiles.push(selectedFile);
          } else {
            dbFiles.push(selectedFile);
          }
        });
      }

      if (uploadFiles.length > 0) {
        try {
          const attachments = await uploadAttachments({
            files: uploadFiles,
            networkNumber,
            questionSequenceNumber: Number(questionSequenceNumberParam),
            questionSetIdParam: siteInfoToSend.name,
            jwtToken: token,
          });

          siteInfoToSend.attachments = [...dbFiles, ...(attachments || [])];
        } catch (error) {
          console.error('Error uploading files:', error);
          return;
        }
      }
      siteInfoToSend.type = 'siteInfo';
      const returnedContract: SiteInfo = await put(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        siteInfoToSend
      );
      const updatedSiteInfo = {
        ...siteInfoToSend,
        sk: returnedContract.sk,
      };

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);
      const updatedSiteInfos =
        siteInfoToSend.sk !== updatedSiteInfo.sk
          ? [...siteInfo, updatedSiteInfo]
          : siteInfo.map((contract) =>
              contract.sk === updatedSiteInfo.sk ? updatedSiteInfo : contract
            );
      setSiteInfo(updatedSiteInfos);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const deleteContact = async (contact: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/contacts/${contact.guid}`,
        token,
        API_TYPE.APPLICATION
      );
      const updatedContacts = contacts.filter((c) => c.guid !== contact.guid);
      setContacts(updatedContacts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const deleteContract = async (contract: Contract) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        { sk: contract.sk }
      );
      const updatedContracts = contracts.filter((c) => c.sk !== contract.sk);
      setContracts(updatedContracts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };
  const deleteSiteInfo = async (siteInf: SiteInfo) => {
    const token = await getTokenFunction();
    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/siteInfoLinks`,
        token,
        API_TYPE.APPLICATION,
        { sk: siteInf.sk }
      );

      const updatedSiteInfo = siteInfo.filter((c) => c.sk !== siteInf.sk);
      setSiteInfo(updatedSiteInfo);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContract'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((employee) => employee.employeeId === employeeId);
    return employee ? `${employee.legalFirstName} ${employee.legalLastName}` : employeeId;
  };

  const EmployeeName = (props: { assignee: Assignment }): JSX.Element => {
    const name = getEmployeeName(props.assignee.koneResourcePersonalNumber);
    return <Typography>{name}</Typography>;
  };

  const SubcontractorName = (props: {
    subcontractor: SubcontractorRecord;
  }): JSX.Element => {
    const { subcontractor } = props;
    return (
      <Typography>
        <Link
          onClick={() => {
            setSelectedItem(subcontractor);
            setShowDialog(DialogType.SUBCONTRACTOR);
          }}
        >
          {subcontractor.subcontractor.name}
        </Link>
      </Typography>
    );
  };

  const SourceChip = (props: { source: RoleSource }): JSX.Element => {
    const { source } = props;
    const text = t(`teamList.source.${source}`);
    const ChipComponent = source === RoleSource.IRMA ? SourceChipLight : SourceChipDark;

    return <ChipComponent>{text}</ChipComponent>;
  };

  const AssigmentInfo = (props: { worker: Worker }): JSX.Element => {
    const { worker } = props;

    const [startDateRaw, endDateRaw, source] = isAssignment(worker)
      ? [worker.assignmentStartDate, worker.assignmentEndDate, worker.source]
      : [worker.plannedStartDate, worker.plannedEndDate, RoleSource.SUPERVISOR];

    const startDate = endDateRaw
      ? formatDate(startDateRaw, 'dd.MM')
      : formatDate(startDateRaw);
    const endDate = endDateRaw ? formatDate(endDateRaw) : 'Not available';

    return (
      <AssigmentInfoContainer container>
        <Grid2 size={{ xs: 6 }}>
          <AssigmentInfoName>
            <SourceChip source={source} />
            {isAssignment(worker) ? (
              <EmployeeName assignee={worker} />
            ) : (
              <SubcontractorName subcontractor={worker} />
            )}
          </AssigmentInfoName>
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <Typography>{`${startDate} - ${endDate}`}</Typography>
        </Grid2>
        <Grid2
          size={{ xs: 1 }}
          container
          justifyContent="flex-end"
          alignContent="flex-start"
        >
          <DeleteButton
            data-testid={`remove-icon-${
              isAssignment(worker) ? 'assignee' : 'subcontractor'
            }-${worker.activityDifferentiator.toLowerCase()}`}
            onClick={() => {
              setItemToRemove(worker);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </DeleteButton>
        </Grid2>
      </AssigmentInfoContainer>
    );
  };

  const ContactInfo = (props: { contact: Contact }): JSX.Element => {
    const { contact } = props;
    return (
      <AssigmentInfoContainer container>
        <Grid2 size={{ xs: 5 }}>
          <Typography>{t(`contactRoles.${contact.role}`)}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <Typography>
            {' '}
            {/*  we can add align right to be as previous  */}
            <Link
              onClick={() => {
                setSelectedItem(contact);
                setShowDialog(DialogType.CONTACT);
              }}
            >
              {contact.firstName && contact.lastName
                ? `${contact.firstName} ${contact.lastName}`
                : contact.firstName || contact.lastName || '-'}
            </Link>
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 2 }}
          container
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <DeleteButton
            data-testid={`remove-icon-contact-${contact.guid}`}
            onClick={() => {
              setItemToRemove(contact);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </DeleteButton>
        </Grid2>
      </AssigmentInfoContainer>
    );
  };

  const ContractInfo = (props: { contract: Contract }): JSX.Element => {
    const { contract } = props;
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 10 }}>
          <Typography style={{ marginLeft: theme.spacing(2), padding: '1px' }}>
            <Link
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                if (contract) {
                  setSelectedItem(contract);
                  setShowDialog(DialogType.LINKS);
                }
              }}
            >
              {contract ? contract.title : 'Title'}
            </Link>
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 2 }}
          container
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <DeleteButton
            data-testid={`remove-icon-contact-${contract.sk}`}
            onClick={() => {
              setItemToRemove(contract);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </DeleteButton>
        </Grid2>
      </Grid2>
    );
  };
  const SiteInfoDetails = (props: { siteInfo: SiteInfo }): JSX.Element => {
    const { siteInfo } = props;
    return (
      <Grid2 container>
        <Grid2 size={{ xs: 10 }}>
          <Typography style={{ marginLeft: theme.spacing(2), padding: '1px' }}>
            <Link
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                if (siteInfo) {
                  setSelectedItem(siteInfo);
                  setShowDialog(DialogType.SITEINFO);
                }
              }}
            >
              {siteInfo
                ? siteInfo.name === SiteInfoType.SITEACCESSINFO
                  ? 'Site access'
                  : siteInfo.name === SiteInfoType.BATHROOMSINFO
                  ? 'Bathrooms'
                  : 'Other notes'
                : 'Title'}
            </Link>
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 2 }}
          container
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <DeleteButton
            data-testid={`remove-icon-contact-${siteInfo.sk}`}
            onClick={() => {
              setItemToRemove(siteInfo);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </DeleteButton>
        </Grid2>
      </Grid2>
    );
  };
  const RemoveDialog = (props: {
    item: Worker | Contact | Contract | SiteInfo;
    onRemove: (item: Worker) => void;
    onCancel: () => void;
  }): JSX.Element => {
    const { item, onRemove, onCancel } = props;

    const isWorker = (item: Worker | Contact | Contract | SiteInfo): item is Worker => {
      return (item as Worker).activityDifferentiator !== undefined;
    };
    const isContract = (
      item: Worker | Contact | Contract | SiteInfo
    ): item is Contract => {
      return (item as Contract).contract !== undefined;
    };
    const isSiteInfo = (
      item: Worker | Contact | Contract | SiteInfo
    ): item is SiteInfo => {
      return (item as SiteInfo).name !== undefined;
    };
    return (
      <>
        <DialogContent
          style={{ paddingTop: theme.spacing(6), paddingBottom: theme.spacing(2) }}
        >
          <Typography align="center" style={{ fontWeight: 'bold' }} gutterBottom>
            {isWorker(item)
              ? t('teamList.remove.title', {
                  name: isAssignment(itemToRemove)
                    ? getEmployeeName(itemToRemove.koneResourcePersonalNumber)
                    : (itemToRemove as SubcontractorRecord)?.subcontractor.name,
                })
              : isContract(item as Contract)
              ? t('teamList.remove.titleContract', {
                  title: (item as Contract).title || 'Contract',
                })
              : isSiteInfo(item)
              ? t('teamList.remove.titleSiteInfo', {
                  name:
                    (item as SiteInfo).name === SiteInfoType.SITEACCESSINFO
                      ? 'Site access'
                      : (item as SiteInfo).name === SiteInfoType.BATHROOMSINFO
                      ? 'Bathrooms'
                      : 'Other notes',
                })
              : t('teamList.remove.titleContact', {
                  name:
                    `${(item as Contact).firstName || ''} ${
                      (item as Contact).lastName || ''
                    }`.trim() || '-',
                })}
          </Typography>
          <Typography align="center" style={{ fontWeight: 'bold' }}>
            {t('teamList.remove.question')}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              isWorker(item)
                ? onRemove(item as Worker)
                : isContract(item)
                ? deleteContract(item as Contract)
                : isSiteInfo(item)
                ? deleteSiteInfo(item as SiteInfo)
                : deleteContact(item as Contact)
            }
            fullWidth
          >
            {t('teamList.remove.confirm')}
          </Button>
          <Button variant="contained" onClick={() => onCancel()} autoFocus fullWidth>
            {t('teamList.remove.cancel')}
          </Button>
        </DialogActions>
      </>
    );
  };
  const initialContact: Contact = {
    role: ContactRole.BUILDER,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    guid: '',
  };
  const intialSiteInfo: SiteInfo = {
    type: 'siteInfo',
    note: '',
    attachments: [],
    name: '' as SiteInfoType,
  };
  const initialContract: Contract = {
    title: ContractType.CONTRACT,
    url: '',
    contract: true,
    type: 'link',
  };
  const contactButtons = [
    {
      label: t('teamList.addCustomer'),
      dialogType: DialogType.CONTACT,
      initialContact: { ...initialContact, role: ContactRole.CUSTOMER },
    },
    {
      label: t('teamList.addCSE'),
      dialogType: DialogType.CONTACT,
      initialContact: {
        ...initialContact,
        role: ContactRole.CSE,
      },
    },
    {
      label: t('teamList.addOtherContact'),
      dialogType: DialogType.CONTACT,
      initialContact: { ...initialContact, role: ContactRole.BUILDER },
    },
  ];

  const siteInfoButtons = [
    {
      label: `+ ${t('teamList.siteInfo.addSiteAccsessInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: { ...intialSiteInfo, name: SiteInfoType.SITEACCESSINFO },
    },
    {
      label: `+ ${t('teamList.siteInfo.addBathroomsInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: {
        ...intialSiteInfo,
        name: SiteInfoType.BATHROOMSINFO,
      },
    },
    {
      label: `+ ${t('teamList.siteInfo.addOtherNoteInfo')}`,
      dialogType: DialogType.SITEINFO,
      intialSiteInfo: { ...intialSiteInfo, name: SiteInfoType.OTHERNOTE },
    },
  ];

  const TeamList = (): JSX.Element => {
    const { installers, testers, serviceEngineers } = filterWorkersByRole(workers);

    const installerTotalHours = Math.ceil(Number(installation.installerHours)) || 0;
    const testerTotalHours = Math.ceil(Number(installation.testerHours)) || 0;
    const installationTotalHours =
      Math.ceil(Number(installation.totalInstallationHours)) || 0;
    const handleAddSiteAccessInfo = (siteInfo: SiteInfo) => {
      setSelectedItem(siteInfo);
      setShowDialog(DialogType.SITEINFO);
    };

    const handleAddContract = (contract: Contract, link?: boolean) => {
      if (contracts.length >= 10) {
        setErrorMsg(true);
        setErrorType('contract');
        return;
      }
      if (link) {
        contract.title = '';
        contract.contract = false;
      }
      setSelectedItem(contract);
      setShowDialog(DialogType.LINKS);
    };

    const handleAddContact = (initialContact: Contact) => {
      if (contacts.length >= 10) {
        setErrorMsg(true);
        setErrorType('contact');
        return;
      }
      setSelectedItem(initialContact);
      setShowDialog(DialogType.CONTACT);
    };
    return (
      <>
        <Grid2 size={{ xs: 12 }}>
          <StyledTypography sx={{ backgroundColor: green[100] }} as="div">
            {t('teamList.installer')}
          </StyledTypography>
          {installers.map((worker, index) => (
            <AssigmentInfo worker={worker} key={index} />
          ))}
          <AddButton
            label={`+ ${t('teamList.addInstaller')}`}
            onClick={() => handleAddTechnician(ActivityDifferentiator.INST)}
          />

          <Typography paragraph={true} align="right">
            {t('teamList.totalHours', { hours: installerTotalHours })}
          </Typography>

          <StyledTypography sx={{ backgroundColor: blue[100] }} as="div">
            {t('teamList.tester')}
          </StyledTypography>
          {testers.map((worker, index) => (
            <AssigmentInfo worker={worker} key={index} />
          ))}
          <AddButton
            label={`+ ${t('teamList.addTester')}`}
            onClick={() => handleAddTechnician(ActivityDifferentiator.CMSN)}
          />

          <Typography
            paragraph={true}
            align="right"
            style={{ textTransform: 'uppercase' }}
          >
            {t('teamList.totalHours', { hours: testerTotalHours })}
          </Typography>

          {serviceEngineers.length > 0 && (
            <>
              <StyledTypography sx={{ backgroundColor: green[100] }} as="div">
                {t('teamList.serviceEngineer')}
              </StyledTypography>
              {serviceEngineers.map((worker, index) => (
                <AssigmentInfo worker={worker} key={index} />
              ))}
            </>
          )}

          <StyledTypography sx={{ backgroundColor: grey[200] }} as="div">
            {t('teamList.links')}
          </StyledTypography>
          <Typography style={{ marginLeft: theme.spacing(2), paddingTop: '5px' }}>
            <Link
              href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/${networkNumber}`}
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('teamList.tracePDM')}
            </Link>
          </Typography>
          <Typography style={{ marginLeft: theme.spacing(2), padding: '1px' }}>
            <Link
              href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/site_surveys/${networkNumber}`}
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('teamList.siteSurvey')}
            </Link>
          </Typography>
          <Typography style={{ marginLeft: theme.spacing(2), padding: '1px' }}>
            <Link
              href={`${process.env.REACT_APP_TRACE_PDM_URL}/equipment/bom/${networkNumber}`}
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('teamList.bom')}
            </Link>
          </Typography>
          {contracts?.map((contract, index) => (
            <ContractInfo contract={contract} key={index} />
          ))}
          <AddButton
            label={'+ Add Contracts'}
            onClick={() => handleAddContract(initialContract)}
            style={{ padding: '5px', marginLeft: theme.spacing(2) }}
          ></AddButton>
          <AddButton
            label={'+ Add Other Link'}
            onClick={() => handleAddContract(initialContract, true)}
            style={{ padding: '5px', marginLeft: theme.spacing(2) }}
          />

          <StyledTypography sx={{ backgroundColor: grey[200] }} as="div">
            {t('teamList.contacts')}
          </StyledTypography>
          {contacts?.map((contact, index) => (
            <ContactInfo contact={contact} key={index} />
          ))}
          {contactButtons.map((button, index) => (
            <AddButton
              key={index}
              label={`+ ${button.label}`}
              onClick={() => {
                handleAddContact(button.initialContact);
              }}
            />
          ))}
          <ErrorMessageDialog
            editValue={errorMsg}
            handleEditChange={setErrorMsg}
            typeOfError={errorType}
          />
          <StyledTypography sx={{ backgroundColor: grey[200] }} as="div">
            SITE INFORMATION AND NOTES
          </StyledTypography>
          {siteInfo.map((siteInfo, index) => (
            <SiteInfoDetails siteInfo={siteInfo} key={index} />
          ))}
          {siteInfoButtons
            .filter(
              (button) =>
                !siteInfo.some(
                  (info) => info.sk && info.name === button.intialSiteInfo.name
                )
            )
            .map((button, index) => (
              <AddButton
                key={index}
                label={button.label}
                onClick={() => {
                  handleAddSiteAccessInfo(button.intialSiteInfo);
                }}
              />
            ))}
          <StyledTypography sx={{ backgroundColor: grey[200] }} as="div">
            {t('teamList.installationTotalHours', {
              hours: installationTotalHours,
            })}
          </StyledTypography>
        </Grid2>
      </>
    );
  };

  return (
    <TeamListContainer>
      <TeamList />
      <Dialog
        data-testid="dialog-form"
        maxWidth="sm"
        fullWidth
        open={showDialog !== DialogType.NONE}
        scroll="paper"
      >
        <CloseDialogIcon
          onClick={() => {
            setShowDialog(DialogType.NONE);
            setSelectedItem(null);
          }}
        />

        {showDialog === DialogType.SUBCONTRACTOR && (
          <SubcontractorForm
            showSubcontractorModal={showDialog === DialogType.SUBCONTRACTOR}
            subcontractor={selectedItem as SubcontractorRecord}
            vendors={vendors}
            setShowSubcontractorModal={(isOpen) =>
              setShowDialog(isOpen ? DialogType.SUBCONTRACTOR : DialogType.NONE)
            }
            onSave={createSubcontractor}
            onRemove={removeSubcontractor}
            setRemoveSubcontractorPopup={setRemoveSubcontractorPopup}
            removeSubcontractorPopup={removeSubcontractorPopup}
          />
        )}

        {showDialog === DialogType.CONTACT && (
          <DialogContent style={{ padding: 0 }}>
            <ContactForm
              contactToEdit={(selectedItem || undefined) as Contact | undefined}
              onSubmit={sendContact}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.LINKS && (
          <DialogContent style={{ padding: 0 }}>
            <ContractForm
              contractToEdit={(selectedItem || undefined) as Contract | undefined}
              onSubmit={sendContract}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.SITEINFO && (
          <DialogContent style={{ padding: 0 }}>
            <SiteInfoForm
              siteInfoToEdit={(selectedItem || undefined) as SiteInfo | undefined}
              onSubmit={sendSiteInfo}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.ADD_TECHNICIAN &&
          potentialKoneAssignees &&
          technicianRole && (
            <DialogContent style={{ padding: 0 }}>
              <AddTechnicianForm
                installation={installation as Installation}
                potentialKoneAssignees={potentialKoneAssignees}
                role={technicianRole}
                vendors={vendors}
                createSubcontractor={createSubcontractor}
                createAssignee={createAssignee}
                onClose={closeTechnicanForm}
              />
            </DialogContent>
          )}
        {showDialog === DialogType.REMOVE && itemToRemove && (
          <RemoveDialog
            item={itemToRemove}
            onRemove={removeItem}
            onCancel={() => setShowDialog(DialogType.NONE)}
          />
        )}
      </Dialog>
    </TeamListContainer>
  );
};

export default TeamList;
