import React, { JSX } from 'react';
import { Box, IconButton, styled, SxProps, Theme } from '@mui/material';

export type IconButtonGroupType = {
  iconSx?: SxProps<Theme>;
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  icon: JSX.Element | null;
  dataTestId: string;
};

const StyledIconButton = styled(IconButton)(({theme}) => ({
  padding: `0 ${theme.spacing(0.5)}px`
}));

export type IconButtonGroupProps = {
  buttons: IconButtonGroupType[];
  className?: string;
};

const IconButtonGroup = (props: IconButtonGroupProps): JSX.Element => {
  const { className, buttons } = props;

  return (
    <Box
      data-testid="icon-button-group"
      display="flex"
      flexDirection="row"
      className={className}
    >
      {buttons.map((button: IconButtonGroupType, index: number) => {
        const { icon, iconSx, onClick, color, dataTestId } = button;
        const key = `icon-button-group--icon-button-${index}`;
        return (
          <StyledIconButton
            key={key}
            data-testid={dataTestId}
            aria-label="icon button"
            sx={iconSx}
            color={color || 'primary'}
            onClick={onClick}
            disabled={!onClick}
          >
            {icon}
          </StyledIconButton>
        );
      })}
    </Box>
  );
};

export default IconButtonGroup;
