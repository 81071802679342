import React, { JSX, useState } from 'react';
import {
  AccountCircle as AccountCircleIcon,
  ImportContacts as UserGuide,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { IconSettings, IconVersionInfo, IconLogout } from '@konecorp/ui-library';

import { useTranslation } from 'react-i18next';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

import VersionInfo from '../VersionInfo';
import Settings from '../Settings';

enum DialogType {
  NONE,
  SETTINGS,
  VERSION_INFO,
}

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 35
}));

interface UserMenuProps {
  onSignOut: () => void;
  title?: string;
}

const USER_GUIDE_LINK = process.env.REACT_APP_LINK_TO_USER_GUIDE || '';

const UserMenu = (props: UserMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const [isSubcontractor] = useIfSubcontractor();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="usermenu-button"
        color="inherit"
        onClick={handleMenu}
        startIcon={<AccountCircleIcon />}
      >
        {props.title} <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="usermenu-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          id="usermenu-menu-settings-item"
          onClick={() => {
            handleClose();
            setOpenedDialog(DialogType.SETTINGS);
          }}
        >
          <StyledListItemIcon>
            <IconSettings />
          </StyledListItemIcon>
          <ListItemText primary={t('userMenu.settings')} />
        </MenuItem>
        <MenuItem
          id="usermenu-menu-info-item"
          onClick={() => {
            handleClose();
            setOpenedDialog(DialogType.VERSION_INFO);
          }}
        >
          <StyledListItemIcon>
            <IconVersionInfo />
          </StyledListItemIcon>
          <ListItemText primary={t('userMenu.versionInfo')} />
        </MenuItem>
        {!isSubcontractor && USER_GUIDE_LINK && (
          <MenuItem
            id="usermenu-menu-user-guide"
            onClick={() => {
              window.open(USER_GUIDE_LINK, '_blank');
            }}
          >
            <StyledListItemIcon>
              <UserGuide />
            </StyledListItemIcon>
            <ListItemText primary={t('userMenu.userGuide')} />
          </MenuItem>
        )}
        <MenuItem
          id="usermenu-menu-signout-item"
          onClick={() => {
            handleClose();
            props.onSignOut();
          }}
        >
          <StyledListItemIcon>
            <IconLogout />
          </StyledListItemIcon>
          <ListItemText primary={t('userMenu.signOut')} />
        </MenuItem>
      </Menu>

      {openedDialog === DialogType.VERSION_INFO && (
        <VersionInfo
          open={openedDialog === DialogType.VERSION_INFO}
          onClose={() => setOpenedDialog(DialogType.NONE)}
        />
      )}

      {openedDialog === DialogType.SETTINGS && (
        <Settings
          open={openedDialog === DialogType.SETTINGS}
          onClose={() => setOpenedDialog(DialogType.NONE)}
        />
      )}
    </>
  );
};

export default UserMenu;
