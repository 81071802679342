import React, { JSX } from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, styled } from '@mui/material';
import { NaviClose } from '@konecorp/ui-library';

import { QuestionSet, AnswerSet, Installation, Deviation } from '../../schemas';
import QuestionSummaryTable from '../../components/QuestionSummaryTable';

export type ReviewFormModalProps = {
  questions: QuestionSet[];
  answers: AnswerSet[];
  networkNumber: string;
  supervisorName: string;
  installationData: Installation | null;
  openDeviations: Deviation[];
  handleCloseOnClick: () => void;
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const StyledToolBar = styled(Toolbar)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const StyledCloseIcon = styled(NaviClose)(() => ({
  fontSize: '2.7rem'
}));

export const ReviewFormModal = ({
  questions,
  answers,
  handleCloseOnClick,
  networkNumber,
  installationData,
  supervisorName,
  openDeviations,
}: ReviewFormModalProps): JSX.Element => {

  return (
    <Dialog fullScreen open={true} aria-label="review-form-modal">
      <StyledAppBar elevation={0}>
        <StyledToolBar>
          <IconButton
            aria-label="close-button"
            color="inherit"
            edge="end"
            onClick={handleCloseOnClick}
          >
            <StyledCloseIcon />
          </IconButton>
        </StyledToolBar>
      </StyledAppBar>

      <DialogContent>
        <QuestionSummaryTable
          supervisorName={supervisorName}
          questionSets={questions}
          answerSets={answers}
          networkNumber={networkNumber}
          installationData={installationData}
          openDeviations={openDeviations}
        />
      </DialogContent>
    </Dialog>
  );
};
