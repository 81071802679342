import React, { JSX } from 'react';
import { Box, styled } from '@mui/material';

export type AlertBoxProps = {
  children?: React.ReactNode;
  className?: string;
};

// Styled Box component with theme-based styling
const NotificationBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  padding: theme.spacing(2),
}));

const AlertBox = (props: AlertBoxProps): JSX.Element => {
  const { children, className = '' } = props;

  if (!children) {
    return <></>;
  }

  return (
    <NotificationBox className={className} role="alert">
      {children}
    </NotificationBox>
  );
};

export default AlertBox;