import React, { useState, useEffect, JSX } from 'react';
import { keyframes, styled, Typography } from '@mui/material';
import { theme } from '@konecorp/ui-library';
import RaccoonImage from './assets/raccoon.png';
import FireworksImage from './assets/fireworks.png';
import { useTranslation } from 'react-i18next';

const HandoverAnimation = (): JSX.Element => {
  
  const slideUpRaccoon = keyframes`
  0% {
    opacity: 0;
    transform: translateY(60%) rotate(-30deg);
  }
  50% {
    opacity: 1;
    transform: translateY(15%) rotate(0deg);
  }
  60% {
    transform: translateY(30%) rotate(-5deg);
  }
  80% {
    transform: translateY(10%) rotate(2deg);
  }
  90% {
    transform: translateY(20%) rotate(5deg);
  }
  100% {
    transform: translateY(15%) rotate(0deg);
  }
`;

const fireworkAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(0) translate(0%, 100%);
  }
`;

const ImageRaccoon = styled('img')<{ animate: boolean }>(({ theme, animate }) => ({
  width: '100%',
  maxWidth: theme.spacing(50),
  margin: '0 auto',
  opacity: 0,
  zIndex: 1,
  transform: 'translateY(60%) rotate(-30deg)',
  ...(animate && {
    animation: `${slideUpRaccoon} 1s ${theme.transitions.easing.easeInOut}`,
    opacity: 1,
    transform: 'translateY(15%) rotate(0deg)',
  }),
}));

const ImageFireworks = styled('img')<{ animate: boolean }>(({ theme, animate }) => ({
  position: 'absolute',
  left: 0,
  width: '90%',
  opacity: 0,
  ...(animate && {
    animation: `${fireworkAnimation} 3s 1.5s ${theme.transitions.easing.easeInOut}`,
  }),
}));

  const StyledContainer = styled('div')(() => ({
    marginBottom: theme.spacing(1)
  }));

  const AnimationContainer = styled('div')(() => ({
    display: 'flex',
  }));

  const TextContainer = styled('div')(() => ({
    flexDirection: 'column',
    zIndex: 2,
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.text.secondary}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    '& h6': {
      fontWeight: 'bold',
    },
  }));

  const [triggerTransition, setTriggerTransition] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!triggerTransition) {
      const timer = setTimeout(() => {
        setTriggerTransition(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <StyledContainer>
      <AnimationContainer>
        <ImageRaccoon
          src={RaccoonImage}
          animate={triggerTransition}
          alt="image-raccoon"
        />
        <ImageFireworks
          src={FireworksImage}
          animate={triggerTransition}
          alt="imageFireworks"
        />
      </AnimationContainer>
      <TextContainer>
        <Typography variant="h6">{t('handover.congratulations')}</Typography>
        <Typography component="div" variant="caption">
          {t('handover.smoothInstallation')}
        </Typography>
        <Typography component="div" variant="caption">
          {t('handover.noOpenDeviations')}
        </Typography>
      </TextContainer>
    </StyledContainer>
  );
};
export default HandoverAnimation;
