import React, { JSX, useContext, useState } from 'react';
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { theme } from '@konecorp/ui-library';
import {
  BuildOutlined,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Deviation, DeviationStatus, DeviationVariation } from '../../schemas';
import Context, { InstallationContext } from '../../context';
import DeviationForm, {
  CreateDeviationPayload,
  DeviationFormPrefill,
  EditDeviationPayload,
} from '../DeviationForm';
import { isInstallationCompleted } from '../../helpers/getInstallationLists';

const StyledListItem = styled(ListItem)(() => ({
  backgroundColor: 'rgb(245,251,255)',
  marginLeft: 5,
  maxWidth: 'calc(100vw - 5px)',
}));

const StyledListItemText = styled(ListItemText)(() => ({
  whiteSpace: 'nowrap',
  maxWidth: 'calc(100vw - 100px)',
}));

const CheckCircleRoundedIcon = styled(CheckCircleRounded)(() => ({
  width: 45,
  height: 45,
}));

const CheckCircleOutlineRoundedIcon = styled(CheckCircleOutlineRounded)(() => ({
  width: 45,
  height: 45,
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  padding: 0,
}));

const BuildOutlinedIcon = styled(BuildOutlined)(() => ({
  width: 30,
  height: 30,
}));

const AddIcon = styled(Add)(() => ({
  width: 30,
  height: 30,
}));

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)(() => ({
  width: 30,
  height: 30,
}));

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)(() => ({
  width: 30,
  height: 30,
}));

const StyledTypography = styled(Typography)(() => ({
  paddingLeft: '1em'
}));

const StyledListItemAction = styled(ListItemSecondaryAction)(() => ({
  right: 0,
  marginRight: theme.spacing(1),
}));

type SnagProps = {
  snag: Deviation;
  openEditForm: (snag: Deviation) => void;
  onCloseSnag: (snag: Deviation) => Promise<void>;
};

const Snag = (props: SnagProps): JSX.Element => {
  const { snag, onCloseSnag, openEditForm } = props;

  return (
    <>
      <StyledListItem>
        <StyledListItemText
          onClick={() => openEditForm(snag)}
          primary={<Typography noWrap>{snag.description}</Typography>}
        />
        <StyledListItemAction>
          <IconButton
            sx={{ padding: 0 }}
            data-testid={`close-snag-${snag.guid}`}
            onClick={async () => await onCloseSnag(snag)}
          >
            {snag.status === DeviationStatus.CLOSED ? (
              <CheckCircleRoundedIcon htmlColor="#59ab46" />
            ) : (
              <CheckCircleOutlineRoundedIcon
                htmlColor={theme.palette.secondary.main}
              />
            )}
          </IconButton>
        </StyledListItemAction>
      </StyledListItem>
      <Divider sx={{ backgroundColor: theme.palette.secondary.light }} light />
    </>
  );
};

export type SnagListProps = {
  id: string;
  questionSetId: string;
  questionSequence: number;
  onCreateSnag: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditSnag: (deviation: EditDeviationPayload) => Promise<void>;
  onCloseSnag: (snag: Deviation) => Promise<void>;
};

const SnagList = (props: SnagListProps): JSX.Element => {
  const { id, questionSetId, questionSequence, onCreateSnag, onEditSnag, onCloseSnag } =
    props;
  const { t } = useTranslation();

  const { installationData } = useContext(Context);
  const { deviations } = useContext(InstallationContext);

  const [openSnagList, setOpenSnagList] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [snagToEdit, setSnagToEdit] = useState<Deviation>();
  const [prefill, setPrefill] = useState<DeviationFormPrefill>();

  const snags = deviations.filter(
    (deviation) =>
      deviation.questionSetId === questionSetId &&
      deviation.questionSequence === questionSequence &&
      deviation.variation === DeviationVariation.SNAG
  );

  const openCreateSnagForm = () => {
    setPrefill({
      questionSetId,
      questionSequence,
      variation: DeviationVariation.SNAG,
    });
    setSnagToEdit(undefined);
    setDialogOpen(true);
  };

  const openEditSnagForm = (snagToEdit: Deviation) => {
    setPrefill(undefined);
    setSnagToEdit(snagToEdit);
    setDialogOpen(true);
  };

  const closeSnagFormDialog = () => {
    setSnagToEdit(undefined);
    setPrefill(undefined);
    setDialogOpen(false);
  };

  const isSnagListEmpty = !snags.length;

  return (
    <TableRow>
      <StyledTableCell>
        <Paper variant="outlined" data-testid={`${id}-snag-list-container`}>
          <Grid2 container>
            <Grid2
              size={{ xs: 1 }}
              sx={{
                backgroundColor: (theme) =>
                  isSnagListEmpty ? theme.palette.action.disabledBackground : theme.palette.info.main,
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <IconButton>
                  <BuildOutlinedIcon
                    htmlColor={theme.palette.background.default}
                  />
                </IconButton>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 8 }} container alignContent="center">
              <StyledTypography
                sx={{
                  color: (theme) =>
                    isSnagListEmpty ? theme.palette.action.disabled : theme.palette.text.primary,
                }}
              >
                {t('snagList.title')} ({snags.length})
              </StyledTypography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <IconButton
                  data-testid={`${id}-snag-add-to-list`}
                  onClick={openCreateSnagForm}
                  disabled={isInstallationCompleted(installationData?.status)}
                >
                  <AddIcon
                    htmlColor={theme.palette.secondary.main}
                  />
                </IconButton>
                <IconButton
                  data-testid={`${id}-snag-open-list`}
                  disabled={isSnagListEmpty}
                  onClick={() => setOpenSnagList(!openSnagList)}
                >
                  {openSnagList ? (
                    <KeyboardArrowUpIcon
                      htmlColor={theme.palette.secondary.main}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.secondary.main}
                    />
                  )}
                </IconButton>
              </Box>
            </Grid2>
          </Grid2>
          <Divider />
          <Collapse unmountOnExit in={openSnagList}>
            <List disablePadding data-testid={`${id}-snag-list`}>
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    isSnagListEmpty ? theme.palette.action.disabledBackground : theme.palette.info.main,
                }}
              >
                {snags.map((snag) => (
                  <Snag
                    key={snag.guid}
                    snag={snag}
                    openEditForm={openEditSnagForm}
                    onCloseSnag={onCloseSnag}
                  />
                ))}
              </Box>
            </List>
          </Collapse>
          <Dialog open={isDialogOpen} fullScreen>
            <DialogContent sx={{ padding: 0 }}>
              <DeviationForm
                initialDeviation={snagToEdit}
                prefill={prefill}
                onCreate={onCreateSnag}
                onEdit={onEditSnag}
                onClear={closeSnagFormDialog}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      </StyledTableCell>
    </TableRow>
  );
};

export default SnagList;
