import React, { ChangeEvent, JSX, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';
import { InfoModal } from '@konecorp/ui-library';
import {
  Answer,
  AnswerValue,
  PutAnswerPayload,
  Question,
  QuestionType,
  QuestionValueType,
} from '../../schemas';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import {
  FloorOrientation,
  useGetFloorOrientation,
} from '../../hooks/useGetFloorOrientation';
import { useIfReadOnly } from '../../hooks/useIfReadOnly';
import NavigationButtons from '../../components/NavigationButtons';
import QDQuestionImage from '../../components/QDQuestionImage';
import RoutingQuestion from '../../components/RoutingQuestion';
import SendToDeviationsButton from '../../components/SendToDeviationsButton';
import CustomizedLocalizedQuestionText from '../../components/CustomizedLocalizedQuestionText';
import AnswerValueType from '../../components/AnswerValueType';

export interface QDQuestionProps {
  question: Question;
  hasNextQuestion: boolean;
  hasPrevQuestion: boolean;
  originalAnswer?: Answer | null;
  onNavigate: (
    originalAnswer: Answer | undefined | null,
    newAnswer: PutAnswerPayload,
    goesForward: boolean
  ) => void;
  className?: string;
  questionSetId: string;
  saveAnswer: (newAnswer: PutAnswerPayload) => Promise<void>;
  onCreateDeviation: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditDeviation: (deviation: EditDeviationPayload) => Promise<void>;
}

const BRACKET_OFFSET = 2;

export const StyledCustomizedLocalizedQuestionText = styled(CustomizedLocalizedQuestionText)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  height: theme.spacing(6),  // Reserve space for long question texts
}));

export const StyledRoutingQuestion = styled(RoutingQuestion)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const FloorInformation = styled('div')<{ isOnline?: boolean }>(({ theme, isOnline }) => ({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.grey[700],
  width: '100px',
  position: 'absolute',
  top: theme.spacing(!isOnline ? 26 : 20),
}));

const QUESTION_WITH_IMAGE_CONTAINER_HEIGHT = '80vh';
const QUESTION_TEXT_AND_NAVIGATION_BUTTON_RESERVED_HEIGHT = '300px';
export const CALCULATED_IMAGE_CONTAINER_HEIGHT = `calc(${QUESTION_WITH_IMAGE_CONTAINER_HEIGHT} - ${QUESTION_TEXT_AND_NAVIGATION_BUTTON_RESERVED_HEIGHT})`;
const shouldDisplayWarning = (
  questionValueType: QuestionValueType | null,
  isInputValid: boolean
) => questionValueType === QuestionValueType.NUMBER && !isInputValid;

const QDQuestion = (props: QDQuestionProps): JSX.Element => {
  const location = useLocation();

  const checkValueIsValid = (value: AnswerValue, question: Question) => {
    if (
      question.valueType?.toLowerCase() === QuestionValueType.NUMBER &&
      typeof value === 'number'
    ) {
      const { negativeTolerance, positiveTolerance, actualValue } = question;
      const minValue = Number(actualValue) - Number(negativeTolerance);
      const maxValue = Number(actualValue) + Number(positiveTolerance);

      return value >= minValue && value <= maxValue;
    }
    return true;
  };

  const getActionIfDifferentLabel = (question: Question): string => {
    let localizedActionIfDifferent = getLocalizedText(i18n, question.actionIfDifferent);
    if (!localizedActionIfDifferent) {
      localizedActionIfDifferent = getLocalizedText(i18n, question.text);
    }
    return localizedActionIfDifferent?.text || '';
  };

  const [isOnline] = useCheckConnection();
  const [getFloorOrientation] = useGetFloorOrientation();
  const [isReadOnlyMode] = useIfReadOnly();

  const [answer, setAnswer] = useState<AnswerValue>(null);
  const [warningShown, setWarningShown] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);
  const [hasUnsavedAnswer, setHasUnsavedAnswer] = useState<boolean>(false);

  const searchQuery = new URLSearchParams(location.search);
  const questionSetId = searchQuery.get('questionSetId') || '';
  const questionNumber = Number(searchQuery.get('questionNumber'));

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const {
      question: { valueType, questionType, actualValue },
      originalAnswer,
    } = props;

    if (
      valueType?.toLowerCase() === QuestionValueType.STRING &&
      questionType?.toLowerCase() === QuestionType.ROUTE
    ) {
      const options = actualValue
        ? actualValue.split(',').map((option) => option.trim())
        : [];
      setAnswer(originalAnswer?.value || options[0] || '');
    } else if (
      valueType?.toLowerCase() === QuestionValueType.YES_NO ||
      valueType?.toLowerCase() === QuestionValueType.NUMBER ||
      valueType?.toLowerCase() === QuestionValueType.OK_NOTOK ||
      valueType?.toLowerCase() === QuestionValueType.OK_NOTOK_NA
    ) {
      if (originalAnswer) {
        setAnswer(originalAnswer.value);
      } else {
        setAnswer(null);
      }
    }
    const isValueValid = checkValueIsValid(originalAnswer?.value || null, props.question);
    setIsInputValid(isValueValid);

    return () => {
      setWarningMessage('');
      setWarningShown(false);
      setAnswer(null);
    };
  }, [props.question]);

  const localizedQuestion = getLocalizedText(i18n, props.question.text);

  const handleCloseWarningModal = () => {
    setIsWarningModalOpen(false);
  };

  const handleNumberInputOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const answer = event.target.value; //this will be string,
    const convertedAnswer = answer !== '' ? Number(answer) : null;
    const isValueValid = checkValueIsValid(convertedAnswer, question);

    setIsInputValid(isValueValid);
    setAnswer(convertedAnswer);
  };

  useEffect(() => {
    const hasNewAnswer =
      (!props.originalAnswer?.value && answer !== null) ||
      (props.originalAnswer && props.originalAnswer.value !== answer);
    setHasUnsavedAnswer(Boolean(hasNewAnswer));
  }, [answer]);

  const navigateQuestion = (question: Question, goesForward: boolean) => {
    const isNewAnswer = answer !== null && answer !== props.originalAnswer?.value;
    if (
      isNewAnswer &&
      !warningShown &&
      shouldDisplayWarning(question.valueType, isInputValid)
    ) {
      const actionIfDifferentLabel = getActionIfDifferentLabel(question);

      setWarningShown(true);
      setWarningMessage(actionIfDifferentLabel);
      setIsWarningModalOpen(true);
      return;
    }

    props.onNavigate(
      props.originalAnswer,
      {
        value: answer,
        tag: question.tag,
        position: question.position,
        timestamp: Date.now(),
      },
      goesForward
    );
  };

  let backwardButtonText;
  let forwardButtonText;
  const isDummyQuestion =
    props.question &&
    props.question.valueType &&
    props.question.valueType.toLowerCase() === QuestionValueType.OK;

  const isRoutingQuestion =
    props.question &&
    props.question.valueType?.toLowerCase() === QuestionValueType.STRING &&
    props.question.questionType?.toLowerCase() === QuestionType.ROUTE;

  const isYesNoQuestion =
    props.question.valueType?.toLowerCase() === QuestionValueType.YES_NO;

  if (isDummyQuestion || isRoutingQuestion) {
    backwardButtonText = t('qdPage.gobackButton');
    forwardButtonText = t('qdPage.continueButton');
  }

  const floorOrientation = getFloorOrientation(props.questionSetId);

  const position =
    floorOrientation === FloorOrientation.TOP_TO_BOTTOM
      ? Number(props.question.reversedPosition)
      : Number(props.question.position);

  const showFloorInfomation =
    props.question &&
    props.question.valueType?.toLowerCase() !== QuestionValueType.OK &&
    Number(props.question.quantityRule) > 1 &&
    position;

  let floorInformationText = t('qdPage.floor');
  let floorOffset = 0;
  if (props.questionSetId === 'TACO01002') {
    // TAC01002 = Shaft mechanics
    floorInformationText = t('qdPage.bracket');
    floorOffset = BRACKET_OFFSET;
  }

  const shouldDisplaySendToDeviationsButton =
    !isDummyQuestion && !isRoutingQuestion && !isYesNoQuestion;

  return (
    <div className={props.className} data-testid="qd-question">
      <Box display="flex" flexDirection="column" height="100%">
        {showFloorInfomation && (
          <FloorInformation
            isOnline={isOnline}
            data-testid="qd-floor"
          >{`${floorInformationText} ${position + floorOffset} / ${
            Number(props.question.quantityRule) + floorOffset
          }`}</FloorInformation>
        )}
        <Box flexGrow={1} maxHeight={CALCULATED_IMAGE_CONTAINER_HEIGHT}>
          <QDQuestionImage imageLink={props.question.imageLink} />
        </Box>
        <Box>
          {isRoutingQuestion ? (
            <StyledRoutingQuestion
              answer={answer as string}
              questionText={localizedQuestion?.text || ''}
              answerOptions={
                props.question.actualValue
                  ? props.question.actualValue.split(',').map((option) => option.trim())
                  : []
              }
              onAnswerChange={(newAnswer: string) => {
                setAnswer(newAnswer);
              }}
              readOnly={isReadOnlyMode}
            />
          ) : (
            <StyledCustomizedLocalizedQuestionText
              localaizedQuestionText={localizedQuestion?.text || ''}
            />
          )}
          <Box pb={10}>
            <NavigationButtons
              forwardButtonText={forwardButtonText}
              backwardButtonText={backwardButtonText}
              onClickBackward={
                props.hasPrevQuestion
                  ? () => navigateQuestion(props.question, false)
                  : undefined
              }
              onClickForward={
                props.hasNextQuestion
                  ? () => navigateQuestion(props.question, true)
                  : undefined
              }
              content={
                <AnswerValueType
                  parentProp={props}
                  question={props.question}
                  answer={answer}
                  isInputValid={isInputValid}
                  handleNumberInputOnChange={handleNumberInputOnChange}
                  questionSetId={questionSetId}
                  questionSequence={questionNumber}
                  setAnswer={setAnswer}
                />
              }
            />
            {hasUnsavedAnswer && !isRoutingQuestion && (
              <Typography variant="caption">{t('qdPage.unsavedWarning')}</Typography>
            )}
            {shouldDisplaySendToDeviationsButton && (
              <Box mt={2}>
                <SendToDeviationsButton />
              </Box>
            )}
          </Box>

          <InfoModal
            closeButtonText="OK"
            message={warningMessage}
            open={isWarningModalOpen}
            onClose={handleCloseWarningModal}
            isCenteredMessage
          />
        </Box>
      </Box>
    </div>
  );
};

export default QDQuestion;
