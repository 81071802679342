import React, { JSX } from 'react';
import {
  LinearProgress,
  Typography,
  Modal,
  Backdrop,
  Fade,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export type CustomizedProgressBarProps = {
  progress: number;
};

// Styled Components
const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1500
}));

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: '0',
  borderRadius: '5px',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4, 4, 4, 4),
  alignItems: 'center',
  zIndex: 1500
}));


const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '10px',
  width: '100%',
  border: '1px solid',
  borderColor: theme.palette.common.black,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
  },
}));


const CustomizedProgressBar = ({ progress }: CustomizedProgressBarProps): JSX.Element => {
  const { t } = useTranslation();

  if (progress !== 100) {
    return (
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          },
        }}
        aria-label="progress-bar-modal"
      >
        <Fade in={true} style={{ opacity: 1 }}>
          <StyledContainer>
            <Typography align="center">
              {t('customizedProgressBar.downloadText')}
            </Typography>
            <br />
            <Typography align="center">
              {t('customizedProgressBar.doNotExitText')}
            </Typography>
            <br />
            <StyledLinearProgress
              variant="determinate"
              value={progress}
              aria-label="progress-bar"
            />
          </StyledContainer>
        </Fade>
      </StyledModal>
    );
  }

  return <></>;
};

export default CustomizedProgressBar;