import React, { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  styled,
  Grid2,
} from '@mui/material';
import { TFunction } from 'i18next';
import {
  IconRoleInstaller,
  IconRoleSupervisor,
  IconRoleTester,
  theme,
} from '@konecorp/ui-library';

import {
  ExtendedInstallation,
  ActivityDifferentiator,
  InstallationStatus,
  Scenario,
} from '../../schemas';
import { formatDate } from '../../helpers/formating';

export type SelfAssignFormProps = {
  networkNumber: string;
  installation: ExtendedInstallation;
  onConfirm: (
    networkNumber: string,
    newRole: ActivityDifferentiator,
    installation?: InstallationStatus | null
  ) => Promise<void>;
  onCancel: () => void;
};

const FormContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const AssignSummary = styled(Box)(() => ({
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.primary.main,
}));

const LabelWithIcon = (props: { icon: JSX.Element; text: string }) => {
  const { icon, text } = props;
  return (
    <Box display="inline-flex" alignItems="center" pt={1}>
      {icon}
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

const getLabelWithIcon = (role: ActivityDifferentiator, t: TFunction): JSX.Element => {
  switch (role) {
    case ActivityDifferentiator.INST:
      return (
        <LabelWithIcon
          icon={<IconRoleInstaller />}
          text={t('selfAssignForm.installer')}
        />
      );
    case ActivityDifferentiator.CMSN:
      return (
        <LabelWithIcon icon={<IconRoleTester />} text={t('selfAssignForm.tester')} />
      );
    case ActivityDifferentiator.SPV:
      return (
        <LabelWithIcon
          icon={<IconRoleSupervisor />}
          text={t('selfAssignForm.supervisor')}
        />
      );
    default:
      return <></>;
  }
};

const SelfAssignForm = (props: SelfAssignFormProps): JSX.Element => {
  const { networkNumber, installation, onConfirm, onCancel } = props;
  const { t } = useTranslation();

  const [assignUserRole, setAssignUserRole] = useState<ActivityDifferentiator>(
    ActivityDifferentiator.INST
  );

  const { customer, firstAssignmentDate, status } = installation;

  const onClickAssignRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssignUserRole(event.target.value as ActivityDifferentiator);
  };

  return (
    <>
      <FormContent>
        <AssignSummary>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 8 }}>
              <Typography variant="subtitle2">{customer?.customerName1}</Typography>
              <Typography variant="subtitle2">{customer?.customerName2}</Typography>
              <Typography variant="subtitle2">
                {`${customer?.street || ''} ${customer?.houseNumber || ''}, 
                  ${customer?.cityPostalCode || ''} ${customer?.city || ''}`}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <Typography variant="subtitle2">{networkNumber}</Typography>
              <Typography variant="subtitle2">
                {formatDate(firstAssignmentDate)}
              </Typography>
            </Grid2>
          </Grid2>
        </AssignSummary>

        <Box pt={2}>
          <Typography>{t('selfAssignForm.assignRole')}</Typography>
        </Box>

        <Box pt={2}>
          <RadioGroup
            aria-label="assign-answer-type"
            name="assign-answer-type"
            value={assignUserRole}
            onChange={onClickAssignRadioButton}
          >
            <FormControlLabel
              key={`radio-button-${ActivityDifferentiator.INST}`}
              aria-label={`radio-button-${ActivityDifferentiator.INST}`}
              value={ActivityDifferentiator.INST}
              control={<Radio color="primary" />}
              label={getLabelWithIcon(ActivityDifferentiator.INST, t)}
            />
            <FormControlLabel
              key={`radio-button-${ActivityDifferentiator.CMSN}`}
              aria-label={`radio-button-${ActivityDifferentiator.CMSN}`}
              value={ActivityDifferentiator.CMSN}
              control={<Radio color="primary" />}
              label={getLabelWithIcon(ActivityDifferentiator.CMSN, t)}
              disabled={installation.scenario === Scenario.INSTALLER_SUPERVISOR}
            />
            <FormControlLabel
              key={`radio-button-${ActivityDifferentiator.SPV}`}
              aria-label={`radio-button-${ActivityDifferentiator.SPV}`}
              value={ActivityDifferentiator.SPV}
              control={<Radio color="primary" />}
              label={getLabelWithIcon(ActivityDifferentiator.SPV, t)}
            />
          </RadioGroup>
        </Box>

        <Box pt={4} pb={2}>
          <Box display="inline" mr={1}>
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me-cancel"
              onClick={() => onCancel()}
            >
              {t('selfAssignForm.cancelButton')}
            </Button>
          </Box>
          <Box display="inline">
            <Button
              variant="contained"
              color="primary"
              aria-label="button-assign-to-me"
              onClick={() => onConfirm(networkNumber, assignUserRole, status)}
            >
              {t('selfAssignForm.assignToMeButton')}
            </Button>
          </Box>
        </Box>
      </FormContent>
    </>
  );
};

export default SelfAssignForm;
